import Button from "common/components/btns/Button";
import InputField from "common/components/inputs/InputField";
import { PopoverWindowContext } from "common/contexts/PopoverWindowProvider";
import { ICreateRowPlaceholderProps } from "common/interfaces/table";
import { useContext } from "react";
import TableAddForm from "./TableAddForm";

const RowPlaceholder = ({
	fieldsAttrs,
	allDropdowns,
	funcsGetRowItem,
	classes,
	funcSubmit,
}: ICreateRowPlaceholderProps) => {
	/**
	 * A table should always have 3 rows (first row to enter data into it, and
	 * last 2 rows just to show the user that he can input multiple rows)
	 *
	 */
	const [content, setContent] = useContext(PopoverWindowContext);

	return (
		<tr className={classes}>
			<td className="options-cell">
				<Button
					size="sm"
					classes="table-add-btn"
					onClick={() => {
						setContent(
							<TableAddForm
								fieldsAttrs={fieldsAttrs}
								allDropdowns={allDropdowns}
								funcsGetRowItem={funcsGetRowItem}
								funcSubmit={funcSubmit}
								funcCloseForm={() => setContent((c: any) => null)}
							/>
						);
					}}
				>
					Dodaj
				</Button>
			</td>
			{fieldsAttrs &&
				Object.entries(fieldsAttrs).map(([key, attrs]: any) => {
					return (
						<td key={key} className="data-cell">
							<span className="width-holder">0</span>
							<InputField size="md" disabled={true} />
						</td>
					);
				})}
		</tr>
	);
};

export default RowPlaceholder;
