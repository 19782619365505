import React, { useContext, useEffect, useState } from "react";
import { FormDataContext } from "common/contexts/FormDataContext";
import Subheading from "common/components/text/Subheading";
import FormAuto from "common/components/forms/FormAuto";
import TableCreateAuto from "common/components/tables/TableCreateAuto";
import FormDataLevelProvider from "common/contexts/FormDataLevelProvider";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Button from "common/components/btns/Button";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import LoaderSmallAndError from "../loaders/LoaderSmallAndError";

const FormMultipartAuto = ({ children, blockId, formSections, funcsGetDropdowns, funcSubmit }: any) => {
	const [data, setData] = useContext(FormDataContext);
	const [resultFromSubmit, sub] = useSubmitterAsync();
	const [refresherKey, setRefresherKey] = useState(0); // this is used to enable refreshForm function

	const refreshForm = () => {
		setRefresherKey((c: number) => c + 1);
	};

	const submitData = async () => {
		sub(() => funcSubmit());
	};

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit) {
			const dataLevel = [blockId];
			setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
			refreshForm();
		}
	}, [resultFromSubmit]);

	if (formSections && formSections.length > 0) {
		return (
			<FormDataLevelProvider blockId={blockId} key={blockId + refresherKey}>
				{formSections.map((obj: any) => {
					return (
						<React.Fragment key={obj["blockId"]}>
							<Subheading> {obj["subheading"]} </Subheading>
							{obj["sectionType"] === "form" && (
								<FormAuto
									blockId={obj["blockId"]}
									funcGetFieldsAttrs={obj["funcGetFieldsAttrs"]}
									funcsGetDropdowns={funcsGetDropdowns}
									funcSubmit={null}
								/>
							)}
							{obj["sectionType"] === "table" && (
								<TableCreateAuto
									blockId={obj["blockId"]}
									funcGetFieldsAttrs={obj["funcGetFieldsAttrs"]}
									funcsGetDropdowns={funcsGetDropdowns}
									funcSubmit={null}
									funcsGetRowItem={obj["funcsGetRowItem"] ? obj["funcsGetRowItem"] : null}
								/>
							)}
							{!obj["sectionType"] && <h5 key={obj["blockId"]}>Wrong form section type</h5>}
						</React.Fragment>
					);
				})}

				<ButtonGroup itemsPosition="c">
					{children}
					<Button size="md" onClick={() => submitData()}>
						Ok
					</Button>
				</ButtonGroup>
			</FormDataLevelProvider>
		);
	} else {
		return (
			<LoaderSmallAndError
				errMsg="Nema nijedne forme. Molimo pokušate ponovo ili kontaktirajte vašeg admina."
				funcTryAgain={refreshForm}
			></LoaderSmallAndError>
		);
	}
};

export default FormMultipartAuto;
