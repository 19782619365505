const Resizer = ({width}: any) => {
  return (
    <>
      <div className="resizer-dragger"></div>
      <div className={`resizer-zone width-${width}`}></div>
    </>
  );
};

export default Resizer;
