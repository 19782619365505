const Hide = () => {
  return (
    <svg height="512" viewBox="0 0 32 32" width="512">
      <path d="m26.707 5.293a1 1 0 0 0 -1.414 0l-3.174 3.174a14.471 14.471 0 0 0 -6.119-1.467c-8.126 0-14.519 8.041-14.787 8.383a1 1 0 0 0 0 1.234 27.724 27.724 0 0 0 6.823 5.933l-2.743 2.743a1 1 0 1 0 1.414 1.414l20-20a1 1 0 0 0 0-1.414zm-15.707 10.707a5.006 5.006 0 0 1 5-5 4.892 4.892 0 0 1 2.73.856l-6.874 6.874a4.892 4.892 0 0 1 -.856-2.73z" />
      <path d="m30.787 15.383a27.937 27.937 0 0 0 -5.116-4.812l-4.741 4.741a4.874 4.874 0 0 1 .07.688 5.006 5.006 0 0 1 -5 5 4.874 4.874 0 0 1 -.688-.07l-3.4 3.4a13.529 13.529 0 0 0 4.088.67c8.126 0 14.519-8.041 14.787-8.383a1 1 0 0 0 0-1.234z" />
    </svg>
  );
};

export default Hide;
