const Calendar = () => {
  return (
    <svg height="512" viewBox="0 0 192 192" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m88 0h16v16h-16z" />
        <path d="m136 0h16v16h-16z" />
        <path d="m40 0h16v16h-16z" />
        <path d="m184 16h-32v24h-16v-24h-32v24h-16v-24h-32v24h-16v-24h-32a8 8 0 0 0 -8 8v32h192v-32a8 8 0 0 0 -8-8z" />
        <path d="m0 184a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-120h-192zm152-96h16v16h-16zm0 32h16v16h-16zm-32-32h16v16h-16zm0 32h16v16h-16zm-32-32h16v16h-16zm0 32h16v16h-16zm-32-32h16v16h-16zm0 32h16v16h-16zm0 32h16v16h-16zm-32-64h16v16h-16zm0 32h16v16h-16zm0 32h16v16h-16z" />
      </g>
    </svg>
  );
};

export default Calendar;
