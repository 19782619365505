const LoaderSmall = () => {
  return (
    <div className="loader">
      <div className="loader-container loader-small">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <span>Molimo sačekajte ...</span>
    </div>
  );
};

export default LoaderSmall;
