import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { IDropdownProps } from "common/interfaces/dropdown";
import createNestedObject from "common/utils/createNestedObject";
import { useContext, useEffect, useRef, useState } from "react";
import FormDataLevelProvider from "../../contexts/FormDataLevelProvider";

/**
 * Use either defaultState (controlled component) or defaultValue (uncontrolled component)
 */

const getDefaultValue = (defaultValue: any) => {
  if (defaultValue) {
    return { defaultValue: defaultValue };
  } else {
    return {};
  }
};

const Dropdown = ({
  size,
  firstEmpty = true,
  defaultState,
  defaultValue,
  items,
  id,
  classes,
  name,
  disabled,
  includeDefaultValInData,
}: IDropdownProps) => {
  const [data, setData] = useContext(FormDataContext);
  const dataLevel: any = useContext(FormDataLevelContext);
  const [val, setVal] = useState();
  const ref = useRef<any>(null);

  const setDefault = (val: any) => {
    setVal(val);
    if (includeDefaultValInData !== false) {
      setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, name], val) }));
    }
  };

  useEffect(() => {
    if (defaultState) {
      setDefault(defaultState); // set default state
    }
    // else if (!firstEmpty && items) {
    //   // this is still not ready. problem is that it puts default value for
    //   // records that should not be sent and will throw required fields error.
    //   setDefault(items[0].value); // set first element as default
    // }
  }, [defaultState]);  // I changed [] to [props.defaultState] on 19.12.2022. Change if makes problems elsewhere.

  return (
    <FormDataLevelProvider blockId={name}>
      <div className={`dropdown-container size-${size} ${classes}`}>
        <select
          {...getDefaultValue(defaultValue)}
          ref={ref}
          name={name}
          id={id}
          value={val}
          disabled={disabled}
          onChange={(e: any) => {
            setVal(e.target.value);
            setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, name], e.target.value) }));
          }}
        >
          {firstEmpty && (
            <option key="-" value="">
              -
            </option>
          )}
          {items &&
            items.map(({ value, text }: any) => {
              return (
                <option key={value} value={value}>
                  {text}
                </option>
              );
            })}
        </select>
      </div>
    </FormDataLevelProvider>
  );
};

export default Dropdown;
