import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";
import { useContext, useEffect, useState } from "react";

const useSubmitterAsync = () => {
	const [resultState, setResultState]: any = useState();
	const [popupNotif, setPopupNotif] = useContext(PopupNotificationsContext);

	const setterPopupNotif = (msg: string, type: string = "") => {
		setPopupNotif((c: any) => [...c, { type: type, msg: msg, dateTime: Date.now() }]);
	};

	const setterResult = (passedResult: any) => {
		setResultState((c: any) => passedResult);
	};

	const submitterFunc = async (funcToRun: any, start_loader = true, succ_msg?: string) => {
		/**
		 * how to pass func with params?? => pass a closure: () => myFunc(param1, param2...)
		 */
		try {
			if (start_loader) {
				setterPopupNotif("obrada...");
			}
			const result = await funcToRun();
			if (typeof result.data === "string") {
				setterPopupNotif(succ_msg ? succ_msg : result.data, "success");
				setterResult(result);
			} else if (result.data instanceof ArrayBuffer) {
				setterPopupNotif(succ_msg ? succ_msg : "Završeno", "success");
				setterResult(result.headers);
			}
		} catch (error: any) {
			console.log("[KERR]", error);
			setterPopupNotif(getMsgFromHttpErrors(error), "error");
		}
	};

	return [resultState, submitterFunc];
};

export default useSubmitterAsync;
