import { Link } from "react-router-dom";

const AdminMenuLinks = (props: any) => {
  return (
    <>
      <div className="category">
        <h4 className="title">Admin options</h4>
        <h5 className="item">
          <Link to="/admin/home" onClick={props.onClick}>
            Početna
          </Link>
        </h5>
        <h5 className="item">
          <Link to="/admin/company" onClick={props.onClick}>
            Naše firme
          </Link>
        </h5>
        <h5 className="item">
          <Link to="/admin/users" onClick={props.onClick}>
            Korisnici
          </Link>
        </h5>
        <h5 className="item">
          <Link to="/admin/permissions" onClick={props.onClick}>
            Ovlaštenja / Grupe ovlaštenja
          </Link>
        </h5>
      </div>
    </>
  );
};

export default AdminMenuLinks;
