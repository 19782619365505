const getFromNestedObject = (obj: any, levelsList: string[]) => {
  let nextLevel: any = obj;
  for (const level of levelsList) {
    nextLevel = nextLevel[level];
    if (!nextLevel) {
      throw "Datalevel [" + level + "] not found.";
    }
  }
  return nextLevel; // you must know what you expect as return (ex. array, obj, string etc..)
};

export default getFromNestedObject;
