import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import TableCreate from "common/components/tables/base_create/TableCreate";
import { useContext, useEffect, useState } from "react";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import { FormDataContext } from "common/contexts/FormDataContext";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";
import TablePlaceholder from "./base_common/TablePlaceholder";
import { ITableCreateAutoProps } from "common/interfaces/table";

const customError = (msg: string) => {
	return {
		response: {
			status: 123,
			data: {
				detail: msg,
			},
		},
	};
};

const TableCreateAuto = ({
	children,
	blockId,
	funcGetFieldsAttrs,
	funcsGetDropdowns,
	funcSubmit,
	funcsGetRowItem,
}: ITableCreateAutoProps) => {
	const [data, setData] = useContext(FormDataContext);
	const [fieldsAttrs, setFieldsAttrs]: any = useState({});
	const [resultFromSubmit, sub] = useSubmitterAsync();
	const [allDropdowns, setAllDropdowns]: any = useState(null);
	const [errMsg, setErrMsg] = useState("");

	const getFields: any = async () => {
		setFieldsAttrs((c: any) => null); // always empty data to run the loader
		try {
			if (funcGetFieldsAttrs) {
				const result = await funcGetFieldsAttrs();
				if (result.data) {
					setFieldsAttrs(result.data["fields"]);
				} else {
					throw customError("Greška! Polja su nedostupna. Pokuštajte ponovo ili kontaktirajte vašeg administratora.");
				}
			}
		} catch (error: any) {
			console.log("[KERR]", error);
			setErrMsg(getMsgFromHttpErrors(error));
		}
	};

	const getDropdowns: any = async () => {
		if (funcsGetDropdowns && Object.keys(funcsGetDropdowns).length > 0) {
			try {
				let results: any = {};
				Object.entries(funcsGetDropdowns).forEach(async ([key, func]: any) => {
					try {
						// we need here also a try catch as the outer one cannot catch 'uncaught promise error'
						results[key] = await func();
						if (results[key]) {
							setAllDropdowns((c: any) => ({ ...c, [key]: results[key].data }));
						}
					} catch (error: any) {
						console.log("[KERR]", error);
						setErrMsg(getMsgFromHttpErrors(error));
					}
				});
			} catch (error: any) {
				console.log("[KERR]", error);
				setErrMsg(getMsgFromHttpErrors(error));
			}
		} else {
			// if no dropdowns needed (not provided)
			setAllDropdowns(null);
		}
	};

	const refreshTable = () => {
		setErrMsg((c: any) => "");
		getFields();
		getDropdowns();
	};

	const submitData = async () => {
		Object.values(data[blockId]).forEach(async (obj: any) => {
			sub(() => funcSubmit(obj));
		});
	};

	useEffect(() => {
		refreshTable();
	}, [funcGetFieldsAttrs]);

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit) {
			const dataLevel = [blockId];
			setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
			refreshTable();
		}
	}, [resultFromSubmit]);

	let dropdownsReady;
	if (funcsGetDropdowns) {
		dropdownsReady = allDropdowns;
	} else {
		// if dropdowns func is not provided (not needed) then set true
		dropdownsReady = true;
	}

	if (fieldsAttrs && dropdownsReady) {
		return (
			<>
				{fieldsAttrs && (
					<TableCreate
						blockId={blockId}
						fieldsAttrs={fieldsAttrs}
						dropdowns={allDropdowns}
						funcsGetRowItem={funcsGetRowItem}
					/>
				)}
				<ButtonGroup itemsPosition="r">
					{funcSubmit && (
						<Button layout="icon-left" icon="ok" size="md" type="button" onClick={() => submitData()}>
							OK
						</Button>
					)}
					{children}
				</ButtonGroup>
			</>
		);
	} else {
		return <TablePlaceholder errMsg={errMsg} funcTryAgain={refreshTable} />;
	}
};

export default TableCreateAuto;
