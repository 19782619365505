import Icons from "common/components/icons/Icons";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { ICheckboxProps } from "common/interfaces/checkbox";
import createNestedObject from "common/utils/createNestedObject";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import { useContext, useEffect, useRef, useState } from "react";

const getCheckboxProps = (props: ICheckboxProps) => {
  const { id, name, includeDefaultValInData, disabled, required }: ICheckboxProps = props;
  let o: any = {}; // props object

  id && (o.id = id);
  name && (o.name = name);
  includeDefaultValInData && (o.includeDefaultValInData = includeDefaultValInData);
  disabled && (o.disabled = disabled);
  required && (o.required = required);
  return o;
};

const Checkbox = (props: ICheckboxProps) => {
  const [data, setData] = useContext(FormDataContext);
  const dataLevel: any = useContext(FormDataLevelContext);
  const [val, setVal] = useState(false);

  const setDefaultState = () => {
    setVal(props.defaultState);
    if (props.includeDefaultValInData !== false) {
      setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], props.defaultState) }));
    }
  };

  useEffect(() => {
    props.defaultState && setDefaultState();
  }, [props.defaultState]);

  return (
    // <div className={`checkbox  size-${props.size} ${props.classes}`}>
    <div className={`checkbox  ${props.classes}`}>
      <input
        type="checkbox"
        checked={val}
        onChange={(e: any) => {
          setVal(e.target.checked);
          setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], e.target.checked) }));
        }}
        {...getCheckboxProps(props)}
      />
    </div>
  );
};

export default Checkbox;
