import { createContext, useState } from "react";

export const PopoverWindowContext = createContext<any>(null);

export const PopoverWindowProvider = ({ children }: any) => {
  const [content, setContent] = useState(null);

  return (
    <PopoverWindowContext.Provider value={[content, setContent]}>
      {children}
    </PopoverWindowContext.Provider>
  );
};
