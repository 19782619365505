import { baseURL } from "common/config/settings";

const ENDPOINTS = {
	documents: `${baseURL}/documents/out`,
	getDocumentPdfById: `${baseURL}/documents/out/pdf/obj`,
	fieldsCreateDocPartDocInfo: `${baseURL}/fields/document/out/create/part/doc-info`,
	fieldsCreateDocPartUnregisteredLegalEntity: `${baseURL}/fields/document/out/create/part/doc-respective/unregistered/leg-ent`,
	fieldsCreateDocPartDocUnregisteredNaturalPerson: `${baseURL}/fields/document/out/create/part/doc-respective/unregistered/nat-pers`,
	fieldsCreateDocPartExistingLegalEntity: `${baseURL}/fields/document/out/create/part/doc-respective/existing/leg-ent`,
	fieldsCreateDocPartExistingNaturalPerson: `${baseURL}/fields/document/out/create/part/doc-respective/existing/nat-pers`,
	fieldsCreateDocPartExistingDocItems: `${baseURL}/fields/document/out/create/part/existing/doc-items`,
	fieldsCreateDocPartUnregisteredDocItems: `${baseURL}/fields/document/out/create/part/unregistered/doc-items`,
	createDocWithLegalEntity: `${baseURL}/document/out/create/with/legal-entity`,
	createDocWithLegalEntityId: `${baseURL}/document/out/create/with/legal-entity/id`,
	createDocWithNaturalPerson: `${baseURL}/document/out/create/with/natural-person`,
	createDocWithNaturalPersonId: `${baseURL}/document/out/create/with/natural-person/id`,
	activateDocument: `${baseURL}/documents/out/active`,
	cancelDocument: `${baseURL}/documents/out/storno`,
	completeDocument: `${baseURL}/documents/out/completed`,
};

export default ENDPOINTS;
