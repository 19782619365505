const DEFAULT_PAGE_SIZE = 10;

const tableUtils = {
	savePageSize(pageSize: number) {
		localStorage.setItem("table_page_size", String(pageSize));
	},
	getPageSize() {
		const ps = localStorage.getItem("table_page_size");
		return ps ? ps : DEFAULT_PAGE_SIZE;
	},
};

const generateFetchParams = (pageNr: number, pageSize: number, sortCols: any[]) => {
	// This function is uset to be passed in a post requrest with the keyword 'fp' and enables to navigate the SQL fetching.
	// sortCols = array of arrays; [column, descending]; (example: [ ["name", false], ["created_on", true] ])
	return {
		pg_nr: pageNr,
		pg_size: pageSize,
		sort_cols: sortCols,
	};
};

export { tableUtils, generateFetchParams };
