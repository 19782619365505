import axios from "axios";
import ENDPOINTS from "applogin/api/endpoints";
import tokenUtils from "../utils/token";

export const getUsernames = async (obj: any) => {
	return await axios.post(ENDPOINTS.usernames, obj, tokenUtils.addTokenToAuthHeaders());
};

export const activateUserByAdmin = async (userId: number) => {
	return await axios.patch(ENDPOINTS.activateUserByAdmin + "/" + userId, {}, tokenUtils.addTokenToAuthHeaders());
};

export const deleteUser = async (userId: number) => {
	return await axios.delete(ENDPOINTS.deleteUser + "/" + userId, tokenUtils.addTokenToAuthHeaders());
};

export const deactivateUserByAdmin = async (userId: number) => {
	return await axios.patch(ENDPOINTS.deactivateUserByAdmin + "/" + userId, {}, tokenUtils.addTokenToAuthHeaders());
};

export const updateUserInfoByAdmin = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateUserInfoByAdmin, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateUserPasswordByAdmin = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateUserPasswordByAdmin, obj, tokenUtils.addTokenToAuthHeaders());
};
