import { createContext, useState } from "react";

export const PopupNotificationsContext = createContext<any>(null);

export const PopupNotificationsProvider = ({ children }: any) => {
  const [popupNotificationsState, setPopupNotificationsState] = useState([]);

  return (
    <PopupNotificationsContext.Provider value={[popupNotificationsState, setPopupNotificationsState]}>
      {children}
    </PopupNotificationsContext.Provider>
  );
};
