import axios from "axios";
import ENDPOINTS from "applogin/api/endpoints";
import tokenUtils from "../utils/token";

export const getMyInfo = async () => {
	return await axios.get(ENDPOINTS.myInfo, tokenUtils.addTokenToAuthHeaders());
};

export const getUsers = async (obj: any) => {
	return await axios.post(ENDPOINTS.users, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateMyPassword = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateMyPassword, obj, tokenUtils.addTokenToAuthHeaders());
};
