import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";

const AdminLayoutContainer = ({ children }: any) => {
  const [userInfoState, setUserInfoState]  = useContext(UserInfoContext);

  if (userInfoState.isAdmin && userInfoState.isAuthenticated) {
    return <div id="admin-layout-container"> {children} </div>;
  }
  return <></>;
};

export default AdminLayoutContainer;
