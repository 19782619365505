import { IIconProps } from "common/interfaces/icons";
import IconsOutline from "./collection_outline/IconsOutline";
import IconsSolid from "./collection_solid/IconsSolid";
import IconsThin from "./collection_thin/IconsThin";

let iconsStyle = "";

const Icons = (props: IIconProps) => {
  switch (iconsStyle) {
    case "thin":
      return <IconsThin icon={props.icon} />;
    case "outline":
      return <IconsOutline icon={props.icon} />;
    default:
      return <IconsSolid icon={props.icon} />;
  }
};

export default Icons;
