const WarningSign = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 511.998 511.998">
      <g>
        <g>
          <path
            d="M508.057,444.469L284.035,37.015c-5.693-10.342-16.179-16.527-28.036-16.527c-11.857,0-22.343,6.185-28.036,16.527
			L3.941,444.469c-5.427,9.932-5.243,21.646,0.512,31.354c5.796,9.83,16.097,15.687,27.544,15.687H480
			c11.448,0,21.749-5.857,27.545-15.687C513.299,466.115,513.484,454.401,508.057,444.469z M250.941,184.752
			c1.72-0.287,3.42-0.43,5.099-0.43c14.745,0,27.749,10.547,30.248,25.394c0.594,3.379,0.532,6.82,0,10.014l-20.193,119.947
			c-0.84,4.956-5.181,8.478-10.076,8.478c-0.553,0-1.147-0.041-1.7-0.143c-4.444-0.737-7.721-4.178-8.417-8.335L225.71,219.73
			C222.905,203.163,234.23,187.496,250.941,184.752z M255.999,430.072c-16.936,0-30.719-13.782-30.719-30.719
			s13.782-30.719,30.719-30.719s30.719,13.783,30.719,30.719S272.935,430.072,255.999,430.072z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WarningSign;
