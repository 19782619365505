import FormDataLevelProvider from "common/contexts/FormDataLevelProvider";
import { ITableCreateProps } from "common/interfaces/table";
import { useRef } from "react";
import HeaderRow from "./parts/HeaderRow";
import CreateRows from "./parts/Rows";

const TableCreate = ({ blockId, classes, fieldsAttrs, dropdowns, funcsGetRowItem }: ITableCreateProps) => {
  const tableRef = useRef<any>();

  return (
    <FormDataLevelProvider blockId={blockId}>
      <div className="table-wrapper">
        <table ref={tableRef} className={`table create ${classes}`}>
          <tbody>
            <HeaderRow fieldsAttrs={fieldsAttrs} />
            <CreateRows tableRef={tableRef} fieldsAttrs={fieldsAttrs} dropdowns={dropdowns} funcsGetRowItem={funcsGetRowItem} />
          </tbody>
        </table>
      </div>
    </FormDataLevelProvider>
  );
};

export default TableCreate;
