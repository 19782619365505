import { FormDataContext } from "common/contexts/FormDataContext";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";
import { useContext, useEffect, useState } from "react";
import TablePlaceholder from "./base_common/TablePlaceholder";
import TableRead from "./base_read_update/TableRead";
import TableUpdate from "./base_read_update/TableUpdate";
import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import { ITableReadUpdateAutoProps } from "common/interfaces/table";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import { tableUtils, generateFetchParams } from "common/components/tables/utils";

const DEFAULT_PAGE_NR = 1;
const DEFAULT_SORT_COLUMN_NAME = "created_on";
const DEFAULT_SORT_DESCENDING = true;

const generateColumnsFromFetchedData: any = (headers: any) => {
	let headersList: any = [];
	let title;
	Object.entries(headers).map(([key, attrs]: any) => {
		if (attrs["required"]) {
			title = "*" + attrs["title"];
		} else {
			title = attrs["title"];
		}
		headersList.push({ Header: title, accessor: key });
	});
	return headersList;
};

const customError = (msg: string) => {
	return {
		response: {
			status: 123,
			data: {
				detail: msg,
			},
		},
	};
};

const TableReadUpdateAuto = ({
	children,
	blockId,
	funcGetData,
	funcsGetDropdowns,
	funcUpdate,
	rowBtnsPropsForRead,
	rowBtnsPropsForUpdate,
}: ITableReadUpdateAutoProps) => {
	const [data, setData] = useContext(FormDataContext);
	const [columns, setColumns]: any[] = useState(null);
	const [pageNr, setPageNr] = useState(DEFAULT_PAGE_NR);
	const [pageSize, setPageSize] = useState(Number(tableUtils.getPageSize()));
	const [sortColumnName, setSortColumnName] = useState(DEFAULT_SORT_COLUMN_NAME);
	const [sortDescending, setSortDescending] = useState(DEFAULT_SORT_DESCENDING);
	const [tableData, setTableData]: any[] = useState(null);
	const [fieldsAttrs, setFieldsAttrs]: any[] = useState(null);
	const [allDropdowns, setAllDropdowns]: any = useState(null);
	const [errMsg, setErrMsg] = useState("");
	const [editActive, setEditActive] = useState(false);
	const [resultFromSubmit, sub] = useSubmitterAsync();
	// const [skipPageReset, setSkipPageReset] = useState(false);

	const getData = async (pgNr: number, pgSize: number, sortColName: string, descending: boolean) => {
		setTableData((c: any) => null); // always empty data to run the loader
		setColumns((c: any) => null); // always empty data to run the loader
		setFieldsAttrs((c: any) => null); // always empty data to run the loader
		try {
			if (funcGetData) {
				const result = await funcGetData(generateFetchParams(pgNr, pgSize, [[sortColName, descending]]));
				if (result.data) {
					setColumns(generateColumnsFromFetchedData(result.data["fields"]));
					setFieldsAttrs(result.data["fields"]);
				} else {
					throw customError(
						"Greška! Nazivi kolona nedostupni. Pokuštajte ponovo ili kontaktirajte vašeg administratora."
					);
				}
				if (result.data) {
					setTableData(result.data["values"]);
				} else {
					throw customError(
						"Greška! Podaci tabele nedostupni. Pokuštajte ponovo ili kontaktirajte vašeg administratora."
					);
				}
			}
		} catch (error: any) {
			console.log("[KERR]", error);
			setErrMsg(getMsgFromHttpErrors(error));
		}
	};

	const getDropdowns: any = async () => {
		setAllDropdowns((c: any) => null); // always empty data to run the loader
		if (funcsGetDropdowns && Object.keys(funcsGetDropdowns).length > 0) {
			try {
				let results: any = {};
				Object.entries(funcsGetDropdowns).forEach(async ([key, func]: any) => {
					try {
						// we need here also a try catch as the outer one cannot catch 'uncaught promise error'
						results[key] = await func();
						if (results[key]) {
							setAllDropdowns((c: any) => ({ ...c, [key]: results[key].data }));
						}
					} catch (error: any) {
						console.log("[KERR]", error);
						setErrMsg(getMsgFromHttpErrors(error));
					}
				});
			} catch (error: any) {
				console.log("[KERR]", error);
				setErrMsg(getMsgFromHttpErrors(error));
			}
		}
	};

	const refreshTable = () => {
		setErrMsg((c: any) => "");
		getData(pageNr, pageSize, sortColumnName, sortDescending);
		getDropdowns();
	};

	const setterPageNr = (nr: number) => {
		if (nr !== pageNr) {
			setPageNr(nr);
			getData(nr, pageSize, sortColumnName, sortDescending);
		}
	};

	const setterPageSize = (nr: number) => {
		if (nr !== pageSize) {
			setPageSize(nr);
			setPageNr(DEFAULT_PAGE_NR);
			getData(DEFAULT_PAGE_NR, nr, sortColumnName, sortDescending);
		}
	};

	const setterColumnSort = (colName: string, descending: boolean) => {
		if (colName !== sortColumnName || descending !== sortDescending) {
			setSortColumnName(colName);
			setSortDescending(descending);
			setPageNr(DEFAULT_PAGE_NR); // this can possibli be commented out and modified to stay on the current page
			getData(DEFAULT_PAGE_NR, pageSize, colName, descending);
		}
	};

	const submitData = async () => {
		Object.entries(data[blockId]).forEach(async ([key, val]: any) => {
			sub(() => funcUpdate({ ...val, ["id"]: key }));
		});
	};

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit) {
			const dataLevel = [blockId];
			setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
			setEditActive((c: any) => false);
			refreshTable();
		}
	}, [resultFromSubmit]);

	useEffect(() => {
		// if no table data fetch data
		if (!tableData) {
			refreshTable();
		}
	}, []);
	// }, [tableData]); // old code, we removed tableData

	let dropdownsReady;
	if (funcsGetDropdowns) {
		dropdownsReady = allDropdowns;
	} else {
		// if dropdowns func is not provided (not needed) then set true
		dropdownsReady = true;
	}

	if (columns && fieldsAttrs && tableData && dropdownsReady) {
		return (
			<>
				<ButtonGroup>
					<Button
						size="md"
						layout="icon-left"
						icon="refresh"
						onClick={() => {
							refreshTable();
						}}
					>
						Osvježi
					</Button>
					{funcUpdate && !editActive && (
						<Button size="md" layout="icon-left" icon="edit" onClick={() => setEditActive((c: any) => true)}>
							Uredi
						</Button>
					)}
					{editActive && (
						<>
							<Button
								size="md"
								layout="icon-left"
								icon="close"
								color="danger"
								onClick={() => {
									setEditActive((c: any) => false);
									const dataLevel = [blockId];
									setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
								}}
							>
								Prekini uređivanje
							</Button>
							<Button size="md" layout="icon-left" icon="ok" onClick={() => submitData()}>
								Spremi uređeno
							</Button>
						</>
					)}
					{!editActive && children}
				</ButtonGroup>
				{!editActive && (
					<TableRead
						hasOptions={children ? true : false}
						blockId={blockId}
						columns={columns}
						tableData={tableData}
						dropdowns={allDropdowns}
						fieldsAttrs={fieldsAttrs}
						rowBtnsProps={rowBtnsPropsForRead}
						setterColumnSort={setterColumnSort}
						currSortCol={sortColumnName}
						sortDesc={sortDescending}
						setterPageNr={setterPageNr}
						setterPageSize={setterPageSize}
						customPageNr={pageNr}
					/>
				)}
				{funcUpdate && editActive && (
					<TableUpdate
						blockId={blockId}
						columns={columns}
						tableData={tableData}
						fieldsAttrs={fieldsAttrs}
						dropdowns={allDropdowns}
						rowBtnsProps={rowBtnsPropsForUpdate}
						setterColumnSort={setterColumnSort}
						currSortCol={sortColumnName}
						sortDesc={sortDescending}
						setterPageNr={setterPageNr}
						setterPageSize={setterPageSize}
						customPageNr={pageNr}
					/>
				)}
			</>
		);
	} else {
		return <TablePlaceholder errMsg={errMsg} funcTryAgain={refreshTable} />;
	}
};

export default TableReadUpdateAuto;
