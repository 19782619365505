import axios from "axios";
import ENDPOINTS from "appdocsout/api/endpoints";
import tokenUtils from "applogin/utils/token";

export const getDocuments = async (obj: any) => {
	return await axios.post(ENDPOINTS.documents, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getDocumentPdfById = async (docId: number, language: string) => {
	// IMPORTANT!!! responseType:'arraybuffer' must be to avoid bad encoding (else, the PDF data will be lost during conversion to JSON)
	return await axios.get(ENDPOINTS.getDocumentPdfById + "/" + language + "/" + docId, {
		...tokenUtils.addTokenToAuthHeaders(),
		responseType: "arraybuffer",
	});
};

export const getFieldsCreateDocPartDocInfo = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartDocInfo, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartUnregisteredLegalEntity = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartUnregisteredLegalEntity, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartExistingLegalEntity = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartExistingLegalEntity, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartDocUnregisteredNaturalPerson = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartDocUnregisteredNaturalPerson, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartExistingNaturalPerson = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartExistingNaturalPerson, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartExistingDocItems = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartExistingDocItems, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateDocPartUnregisteredDocItems = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateDocPartUnregisteredDocItems, tokenUtils.addTokenToAuthHeaders());
};

export const createDocWithLegalEntity = async (obj: any) => {
	return await axios.post(ENDPOINTS.createDocWithLegalEntity, obj, tokenUtils.addTokenToAuthHeaders());
};

export const createDocWithLegalEntityId = async (obj: any) => {
	return await axios.post(ENDPOINTS.createDocWithLegalEntityId, obj, tokenUtils.addTokenToAuthHeaders());
};

export const createDocWithNaturalPerson = async (obj: any) => {
	return await axios.post(ENDPOINTS.createDocWithNaturalPerson, obj, tokenUtils.addTokenToAuthHeaders());
};

export const createDocWithNaturalPersonId = async (obj: any) => {
	return await axios.post(ENDPOINTS.createDocWithNaturalPersonId, obj, tokenUtils.addTokenToAuthHeaders());
};

export const activateDocument = async (id: number) => {
	return await axios.patch(ENDPOINTS.activateDocument + "/" + id, {}, tokenUtils.addTokenToAuthHeaders());
};

export const cancelDocument = async (id: number) => {
	return await axios.patch(ENDPOINTS.cancelDocument + "/" + id, {}, tokenUtils.addTokenToAuthHeaders());
};

export const completeDocument = async (id: number) => {
	return await axios.patch(ENDPOINTS.completeDocument + "/" + id, {}, tokenUtils.addTokenToAuthHeaders());
};
