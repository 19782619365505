// import React from "react";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "common/routes/PrivateRoutes";
import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import PublicRoutes from "common/routes/PublicRoutes";
import { useContext, useEffect, useState } from "react";
import tokenUtils from "applogin/utils/token";
import TopNavZone from "common/components/layout_zones/TopNavZone";
import WorkZone from "common/components/layout_zones/WorkZone";
import PrivateLayoutContainer from "common/views_private/PrivateLayoutContainer";
import PublicLayoutContainer from "common/views_public/PublicLayoutContainer";
import TopNav from "common/components/nav_top/TopNav";
import { IUserInfoFetched, IUserInfo } from "applogin/interfaces/users";
import { getOnlyCommonAttrs } from "utils";
import AdminLayoutContainer from "common/views_admin/AdminLayoutContainer";
import AdminRoutes from "common/routes/AdminRoutes";
import { getMyInfo } from "applogin/api/users";
import PopupNotificationsZone from "common/components/layout_zones/PopupNotificationsZone";
import PopoverWindow from "common/components/popover_window/PopoverWindow";
import LoaderFullPage from "common/components/loaders/LoaderFullPage";
import { USER_CONSTS } from "applogin/constants/userConsts";
import axios from "axios";

function App() {
	const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
	const [fullPageLoader, setFullPageLoader] = useState(false);

	useEffect(() => {
		axios.interceptors.response.use(
			function (succResponse) {
				// ... modify successful response
				return succResponse;
			},
			function (err) {
				if (err.response.status == 401) {
					// LOGOUT USER
					tokenUtils.removeLocalToken();
					tokenUtils.removeLocalTUT();
					setUserInfoState(USER_CONSTS.initialUserInfo); //destroy the logged in object
				}
				return Promise.reject(err);
			}
		);

		let token;
		let tut;
		if (!userInfoState.token) {
			token = tokenUtils.getLocalToken();
		}
		if (!userInfoState.tut) {
			tut = tokenUtils.getLocalTUT();
		}
		async function getMyInfoOrRemoveLocalTokens(token: string | null | undefined, tut: string | null | undefined) {
			if (token && tut) {
				try {
					setFullPageLoader(true);
					const result = await getMyInfo();
					const fetchedUserInfo: IUserInfoFetched = result.data;
					const adjustedUserInfo: IUserInfoFetched = getOnlyCommonAttrs(fetchedUserInfo, userInfoState);
					let newUserInfoState: IUserInfo = {
						...userInfoState,
						...adjustedUserInfo,
						isAdmin: fetchedUserInfo.is_admin,
						isAuthenticated: true,
						token: token,
						tut: tut,
					};
					setUserInfoState({ ...newUserInfoState });
				} catch (error: any) {
					tokenUtils.removeLocalToken();
					tokenUtils.removeLocalTUT();
				} finally {
					setFullPageLoader(false);
				}
			}
		}
		getMyInfoOrRemoveLocalTokens(token, tut);
	}, []);

	return (
		<BrowserRouter>
			<AdminLayoutContainer>
				<TopNavZone>
					<TopNav />
				</TopNavZone>
				<WorkZone>
					<AdminRoutes />
				</WorkZone>
				<PopupNotificationsZone />
			</AdminLayoutContainer>
			<PrivateLayoutContainer>
				<TopNavZone>
					<TopNav />
				</TopNavZone>
				<WorkZone>
					<PrivateRoutes />
				</WorkZone>
				<PopupNotificationsZone />
			</PrivateLayoutContainer>
			<PublicLayoutContainer>
				{fullPageLoader && <LoaderFullPage />}
				<PublicRoutes />
			</PublicLayoutContainer>

			<PopoverWindow />
		</BrowserRouter>
	);
}

export default App;
