import {
  createLegalEntity,
  getFieldsCreateLegalEntity,
} from "appassociates/api/associates";
import {getCountriesDropdown} from "appunits/api/countries";
import FormAuto from "common/components/forms/FormAuto";
import Subheading from "common/components/text/Subheading";

const LegalEntityAddForm = () => {
  const blockId = "legal_entity_add";

  const funcsGetDropdowns = {
    country_code_iso: getCountriesDropdown,
    // legal_entity_type_id: getLegalEntityTypesDropdown,
  };

  return (
    <>
      <Subheading>Dodaj pravno lice:</Subheading>
      <FormAuto
        blockId={blockId}
        funcGetFieldsAttrs={getFieldsCreateLegalEntity}
        funcsGetDropdowns={funcsGetDropdowns}
        funcSubmit={createLegalEntity}
      ></FormAuto>
    </>
  );
};

export default LegalEntityAddForm;
