import TableReadUpdate from "common/components/tables/TableReadUpdateAuto";
import { getPermissions } from "applogin/api_admin/permissions";
import Subheading from "common/components/text/Subheading";

const PermissionsTable = (props: any) => {
  const blockId = "permissions";

  // const funcsGetDropdowns = {
  //   key: func,
  // };

  return (
    <>
      <Subheading>Permissions:</Subheading>
      <TableReadUpdate
        blockId={blockId}
        funcGetData={getPermissions}
        funcsGetDropdowns={null}
        funcUpdate={null}
      ></TableReadUpdate>
    </>
  );
};

export default PermissionsTable;
