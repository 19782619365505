import LoaderSmallAndError from "common/components/loaders/LoaderSmallAndError";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";
import { useEffect, useState } from "react";

const customError = (msg: string) => {
	return {
		response: {
			status: 123,
			data: {
				detail: msg,
			},
		},
	};
};

const CurrentImage = ({ funcGetCurrentImage, size, classes }: any) => {
	const [dataURL, setDataURL]: any = useState(null);
	const [errMsg, setErrMsg] = useState("");

	const getCurrentImage: any = async () => {
		setDataURL((c: any) => null); // always empty data to run the loader
		try {
			if (funcGetCurrentImage) {
				const result = await funcGetCurrentImage();
				// if (result.data) {
				//   setDataURL((c: any) => window.URL.createObjectURL(new Blob([result.data], { type: "image/png" })));
				// } else {
				//   throw customError("Izgleda da još uvijek niste dodijelili logo.");
				// }
				setDataURL((c: any) => window.URL.createObjectURL(new Blob([result.data], { type: "image/png" })));
			}
		} catch (error: any) {
			// as response type is array buffer we have to convert response to text/json
			// setErrMsg(JSON.parse(new TextDecoder("utf-8").decode(new Uint8Array(error.response.data)))["detail"]);
			setErrMsg("Nema logo. Zašto ne dodate jedan?");
		}
	};

	const refreshForm = () => {
		setErrMsg((c: any) => "");
		getCurrentImage();
	};

	useEffect(() => {
		refreshForm();
		return () => {
			setDataURL((c: any) => null);
			window.URL.revokeObjectURL(dataURL); // clean-up memory on unmount
		};
	}, []);

	if (dataURL) {
		return <img className={`current-image size-${size} ${classes}`} src={dataURL} alt="current image" />;
	} else {
		return <LoaderSmallAndError align="center" errMsg={errMsg} funcTryAgain={refreshForm}></LoaderSmallAndError>;
	}
};

export default CurrentImage;
