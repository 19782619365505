import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminCompanies from "common/views_admin/AdminCompanies";
import AdminHome from "common/views_admin/AdminHome";
import AdminPermissions from "common/views_admin/AdminPermissions";
import AdminUsers from "common/views_admin/AdminUsers";

const AdminRoutes = () => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

  if (userInfoState.isAuthenticated && userInfoState.isAdmin) {
    return (
      <Routes>
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/company" element={<AdminCompanies />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/permissions" element={<AdminPermissions />} />
        <Route path="*" element={<Navigate to={"/admin/home"} />} />
      </Routes>
    );
  }
  return <></>;
};

export default AdminRoutes;
