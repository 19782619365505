import { useEffect, useRef, useState } from "react";
import Button from "common/components/btns/Button";
import { IPopoverProps } from "common/interfaces/popover";

const Popover = (props: IPopoverProps) => {
  const [showPopover, setShowPopover] = useState(props.defaultShow ? props.defaultShow : false);
  const ref = useRef(null);
  const ref_2 = useRef(null);

  const handleShowHide = () => {
    setShowPopover(!showPopover);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target) && !ref_2.current.contains(event.target)) {
        handleShowHide();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleShowHide]);

  return (
    <div className={`popover-wrapper ${props.classes}`} id={props.id}>
      <Button
        ref={ref_2}
        size={props.size}
        layout={props.layout}
        icon={!showPopover ? props.icon : props.iconActive ? props.iconActive : props.icon}
        helperText={props.helperText}
        color={props.color}
        onClick={handleShowHide}
        id="close-popover"
      >
        {props.btnContent}
      </Button>
      {showPopover && (
        <div className={`popover-card`}>
          <div ref={ref} className="popover-card-content">
            <div className="close-zone">
              <Button size="lg" layout="icon-only" icon="clear" onClick={handleShowHide}></Button>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Popover;
