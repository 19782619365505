import ItemsTable from "appitems/components/ItemsTable";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const Items = () => {
  return (
    <>
      <Heading>Stavke</Heading>

      <Section>
        <ItemsTable />
      </Section>
    </>
  );
};

export default Items;
