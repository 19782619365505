import { useState } from "react";
import InputField from "common/components/inputs/InputField";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const placeholder = "Pretraži - opcije, navigacija, dokumenti...";

  return (
    <InputField size="md" layout="icon-left" icon="search" classes="search-bar" placeholder={placeholder}></InputField>
  );

  // return (
  //   <div className="search-bar">
  //     <div className="zone-icon">
  //       <img src={icon} alt="Search bar icon" />
  //     </div>
  //     <div className="zone-input">
  //       <input
  //         type="text"
  //         onChange={(e: any) => {
  //           setSearchTerm(e.target.value);
  //         }}
  //         placeholder={placeholder}
  //       />
  //     </div>
  //     <div className="zone-button">
  //       <button className="btn-clear">
  //         <img src={icon2} alt="Clear icon" />
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default SearchBar;
