import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import { useContext, useEffect, useState } from "react";
import {
	activateDocument,
	cancelDocument,
	completeDocument,
	getDocumentPdfById,
	getDocuments,
} from "appdocsout/api/documents";
import { PopoverWindowContext } from "common/contexts/PopoverWindowProvider";
import PDFContainer from "apppdf/components/PDFContainer";
import Subheading from "common/components/text/Subheading";
import { IRowBtnsProps } from "common/interfaces/table";
import Button from "common/components/btns/Button";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";
import getFromNestedObject from "common/utils/getFromNestedObject";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";

const DocsOutTable = ({ docTypeId, title }: any) => {
	const blockId = "docs_out";
	const [resultFromSubmit, sub] = useSubmitterAsync();
	const [data, setData] = useContext(FormDataContext);
	const dataLevel: any = useContext(FormDataLevelContext);
	const [content, setContent] = useContext(PopoverWindowContext);
	const [refresherKey, setRefresherKey] = useState(0); // this is used to enable refreshForm function
	const [popupNotif, setPopupNotif] = useContext(PopupNotificationsContext);
	const [refreshAfterSubmit, setRefreshAfterSubmit] = useState(true);

	// const funcsGetDropdowns = {
	//   key: func,
	// };

	const viewPDF = async (docId: any, language: string) => {
		const result = await getDocumentPdfById(docId, language);
		if (result) {
			setContent(<PDFContainer data={result.data} fileName={result.headers["filename"]} />);
			return result;
		}
	};

	///// This is the way if we want a button in the row (options)
	// const rowBtnsPropsForRead: IRowBtnsProps[] = [
	// 	{
	// 		text: "Pogledaj PDF",
	// 		layout: "",
	// 		icon: "",
	// 		color: "",
	// 		func: viewPDF,
	// 	},
	// ];

	const refreshForm = () => {
		setRefresherKey((c: number) => c + 1);
	};

	const msgNoFieldSelected = (msg: string) => {
		setPopupNotif((c: any) => [...c, { type: "error", msg: msg, dateTime: Date.now() }]);
	};

	const handleOptionClick = (func: any) => {
		let nothingFound = true;
		getFromNestedObject(data, [...dataLevel, blockId]).map((id: any) => {
			nothingFound = false;
			sub(() => func(id));
		});
		nothingFound && msgNoFieldSelected("Morate odabrati barem jedan red u tabeli.");
	};

	const handleOptionClickPDF = (func: any, language: string) => {
		setRefreshAfterSubmit(false);
		let nothingFound = true;
		getFromNestedObject(data, [...dataLevel, blockId]).map((id: any) => {
			nothingFound = false;
			// func(id, language);
			sub(() => func(id, language), true, "PDF spreman");
		});
		nothingFound && msgNoFieldSelected("Morate odabrati jedan red u tabeli.");
	};

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit && refreshAfterSubmit) {
			setData((c: any) => ({ ...deleteFromNestedObject(c, [...dataLevel, blockId]) }));
			refreshForm();
		}
		setRefreshAfterSubmit(true);
	}, [resultFromSubmit]);

	return (
		<>
			<Subheading>{title}</Subheading>
			<TableReadUpdateAuto
				key={refresherKey}
				blockId={blockId}
				funcGetData={(fetchParams: object) =>
					getDocuments({
						codename: docTypeId,
						fp: fetchParams,
					})
				}
				funcsGetDropdowns={null}
				funcUpdate={null}
				rowBtnsPropsForRead={null}
				// rowBtnsPropsForRead={rowBtnsPropsForRead}
				rowBtnsPropsForUpdate={null}
			>
				<Button size="md" type="button" layout="text" onClick={() => handleOptionClickPDF(viewPDF, "bs")}>
					PDF (bs)
				</Button>
				<Button size="md" type="button" layout="text" onClick={() => handleOptionClickPDF(viewPDF, "en")}>
					PDF (en)
				</Button>
				<Button size="md" type="button" layout="text" onClick={() => handleOptionClickPDF(viewPDF, "de")}>
					PDF (de)
				</Button>
				<Button size="md" type="button" layout="text" onClick={() => handleOptionClick(activateDocument)}>
					Aktiviraj
				</Button>
				<Button size="md" type="button" layout="text" onClick={() => handleOptionClick(completeDocument)}>
					Zaključi
				</Button>
				<Button size="md" type="button" color="danger" onClick={() => handleOptionClick(cancelDocument)} layout="text">
					Storniraj
				</Button>
			</TableReadUpdateAuto>
		</>
	);
};

export default DocsOutTable;
