export function getOnlyCommonAttrs(objToTrim: any, objForCompare: any): any {
  return Object.fromEntries(Object.entries(objToTrim).filter(([key, value]) => key in objForCompare));
}

export function updateMainObj(objToUpdate: any, objWithNewVals: any): any {
  return { ...objToUpdate, ...objWithNewVals };
}

export function removeNullAttrs(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
}
