import UploadFile from "common/components/inputs/UploadFile";
import Heading from "common/components/text/Heading";
import { getLogo, uploadLogo } from "appcompany/api/companies";
import Section from "common/components/layout_zones/Section";
import CompaniesTable from "appcompany/components/CompaniesTable";

const CompanyInfo = () => {
  return (
    <>
      <Heading>Moja firma</Heading>

      <Section>
        <UploadFile title="Logo firme:" funcSubmit={uploadLogo} funcGetCurrentImage={getLogo} currentImageSize="" />
      </Section>

      <Section>
        <CompaniesTable />
      </Section>
    </>
  );
};

export default CompanyInfo;
