import ENDPOINTS from "applogin/api/endpoints";
import axios from "axios";
import tokenUtils from "../utils/token";

export const getFieldsRegisterUser = async () => {
	return await axios.get(ENDPOINTS.fieldsRegisterUser, tokenUtils.addTokenToAuthHeaders());
};

export const registerUser = async (obj: any) => {
	return await axios.post(ENDPOINTS.registerUser, obj, tokenUtils.addTokenToAuthHeaders());
};
