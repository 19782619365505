// const currentEnv = process.env.REACT_APP_ENV;

function getBaseURLByEnv() {
	if (process.env.REACT_APP_BACKEND_LOCAL_URL) {
		// Windows powershell (copy whole line and paste into command line):
		// $env:REACT_APP_BACKEND_LOCAL_URL = "http://localhost:8000"
		const baseURL = process.env.REACT_APP_BACKEND_LOCAL_URL; // example: "http://localhost:8000"
		console.log("backend is local, at: " + baseURL);
		return baseURL;
	} else {
		return "https://dev.f.erp.kadalis.com/erp";
	}
}

const baseURL = getBaseURLByEnv();
const baseURLUpload = `${baseURL}/upload`

export { baseURL, baseURLUpload };
