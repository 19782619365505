import DocsOutTable from "appdocsout/components/DocsOutTable";
import { DocTypeEnum } from "appdocsout/enums/docTypes";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const AdvanceInvoicesOut = () => {
  return (
    <>
      <Heading>Avansne fakture (izlazne)</Heading>
      <Section>
        <DocsOutTable docTypeId={DocTypeEnum.AdvanceInvoice} title="Lista izlaznih avansnih faktura" />
      </Section>
    </>
  );
};

export default AdvanceInvoicesOut;
