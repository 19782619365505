import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";

const PublicLayoutContainer = ({ children }: any) => {
  const [userInfoState, setUserInfoState]  = useContext(UserInfoContext);

  if (!userInfoState.isAuthenticated) {
    return <div id="public-layout-container"> {children} </div>;
  }
  return <></>;
};

export default PublicLayoutContainer;
