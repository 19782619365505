import axios from "axios";
import ENDPOINTS from "applogin/api/endpoints";
import tokenUtils from "../utils/token";

export const getPermissions = async () => {
	return await axios.get(ENDPOINTS.permissions, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreatePermission = async (obj: any) => {
	return await axios.get(ENDPOINTS.fieldsPermissionsCreateByAdmin, tokenUtils.addTokenToAuthHeaders());
};

export const createPermission = async (obj: any) => {
	return await axios.post(ENDPOINTS.permissionsCreateByAdmin, obj, tokenUtils.addTokenToAuthHeaders());
};
