import Subheading from "common/components/text/Subheading";
import { createPermission, getFieldsCreatePermission } from "applogin/api_admin/permissions";
import FormAuto from "common/components/forms/FormAuto";

const PermissionAddForm = () => {
  const blockId = "permission_add";

  // const funcsGetDropdowns = {
  //   key: func,
  // };

  return (
    <>
      <Subheading>Kreiraj ovlaštenje:</Subheading>
      <FormAuto
        blockId={blockId}
        funcGetFieldsAttrs={getFieldsCreatePermission}
        funcSubmit={createPermission}
        funcsGetDropdowns={null}
      ></FormAuto>
    </>
  );
};

export default PermissionAddForm;
