import { Route, Routes } from "react-router-dom";
import UserProfile from "common/views_private/UserProfile";
import UserSettings from "common/views_private/UserSettings";
import UserHome from "common/views_private/UserHome";
import UserSupport from "common/views_private/UserSupport";

const UserRoutes = () => {
  return (
    <>
      <Route path="/user/home" element={<UserHome />} />
      <Route path="/user/profile" element={<UserProfile />} />
      <Route path="/user/settings" element={<UserSettings />} />
      <Route path="/user/support" element={<UserSupport />} />
    </>
  );
};

export default UserRoutes;
