import DocsOutTable from "appdocsout/components/DocsOutTable";
import { DocTypeEnum } from "appdocsout/enums/docTypes";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const EstimatesOut = () => {
  return (
    <>
      <Heading>Ponude (izlazne)</Heading>
      <Section>
        <DocsOutTable docTypeId={DocTypeEnum.Estimate} title="Lista izlaznih ponuda" />
      </Section>
    </>
  );
};

export default EstimatesOut;
