import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import { getItems, updateItem } from "appitems/api/items";
import Subheading from "common/components/text/Subheading";
import { getUnitsOfMeasurementDropdown } from "appunits/api/units_of_measurement";
import { getCurrenciesDropdown } from "appunits/api/currencies";

const ItemsTable = () => {
	const blockId = "table_items";

	const funcsGetDropdowns = {
		unit_of_measurement_id: getUnitsOfMeasurementDropdown,
		currency_id: getCurrenciesDropdown,
	};

	return (
		<>
			<Subheading>Stavke</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getItems({ fp: fetchParams })}
				funcUpdate={updateItem}
				funcsGetDropdowns={funcsGetDropdowns}
			/>
		</>
	);
};

export default ItemsTable;
