const tokenUtils = {
	saveLocalToken(token: string) {
		localStorage.setItem("token", token);
	},
	saveLocalTUT(tut: string) {
		localStorage.setItem("tut", tut);
	},
	getLocalToken() {
		return localStorage.getItem("token");
	},
	getLocalTUT() {
		return localStorage.getItem("tut");
	},
	removeLocalToken() {
		localStorage.removeItem("token");
	},
	removeLocalTUT() {
		localStorage.removeItem("tut");
	},
	addTokenToAuthHeaders() {
		return {
			headers: {
				token: `${tokenUtils.getLocalToken()}`, // must not be Null, so we must cover into a string
				tut: `${tokenUtils.getLocalTUT()}`, // must not be Null, so we must cover into a string
			},
		};
	},
};

export default tokenUtils;
