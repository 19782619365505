import Icons from "common/components/icons/Icons";
import { IButtonProps } from "common/interfaces/button";
import { forwardRef } from "react";

const getBtnProps = (props: any) => {
  const { type, disabled, id } = props;
  let o: any = {}; // props object

  type ? (o.type = type) : (o.type = "button");
  id && (o.id = id);
  disabled && (o.disabled = disabled);
  return o;
};

const getLayout = (props: any) => {
  switch (props.layout) {
    case "icon-only":
      return "btn-type-icon";
    case "icon-left":
      return "btn-type-icon-left";
    case "icon-right":
      return "btn-type-icon-right";
    case "text-left":
      return "btn-type-text-left";
    case "text-right":
      return "btn-type-text-right";
    default: // empty is normal button
      return "";
  }
};

let Button = (props: IButtonProps, ref: any) => {
  return (
    <button
      {...getBtnProps(props)}
      ref={ref}
      onClick={props.onClick}
      className={`btn-container ${getLayout(props)} size-${props.size} ${props.color} ${
        props.colorTextInvert && "color-text-invert"
      } w-${props.widthPercent} ${props.classes}`}
    >
      <div className="btn-content">
        {props.icon && (
          <div className="icon-container">
            <Icons icon={props.icon} />
          </div>
        )}
        {props.helperText && <div className="helper-text">{props.helperText}</div>}
        {props.children && <div className="text">{props.children}</div>}
      </div>
    </button>
  );
};

//@ts-ignore
Button = forwardRef(Button);

export default Button;
