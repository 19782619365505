const Folder = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 408 408">
      <g>
        <g>
          <path
            d="M372,88.661H206.32l-33-39.24c-0.985-1.184-2.461-1.848-4-1.8H36c-19.956,0.198-36.023,16.443-36,36.4v240
			c-0.001,19.941,16.06,36.163,36,36.36h336c19.94-0.197,36.001-16.419,36-36.36v-199C408.001,105.08,391.94,88.859,372,88.661z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Folder;
