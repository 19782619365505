const Copy = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m241 210h109.99l-109.99-82.493z" />
        <path d="m76 512h270c8.291 0 15-6.709 15-15v-257h-135c-8.291 0-15-6.709-15-15v-105h-135c-8.291 0-15 6.709-15 15v362c0 8.291 6.709 15 15 15z" />
        <path d="m451 376v-256h-135c-8.291 0-15-6.709-15-15v-105h-135c-8.291 0-15 6.709-15 15v75h75c9.683 0 19.277 3.208 27.012 9.023l119.985 89.985c11.265 8.424 18.003 21.886 18.003 35.992v166h45c8.291 0 15-6.709 15-15z" />
        <path d="m331 90h109.99l-109.99-82.493z" />
      </g>
    </svg>
  );
};

export default Copy;
