import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";
import Subheading from "common/components/text/Subheading";
import Text from "common/components/text/Text";
import logo from "assets/icons/logo.svg";
import { useContext } from "react";
import { UserInfoContext } from "applogin/contexts/UserInfoProvider";

const UserHome = () => {
	const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
	return (
		<>
			<Heading>Početna</Heading>
			<Subheading>Vaši prvi koraci</Subheading>
			<Text>
				<ol>
					<li className="m-b-100">
						Treba da znate da nam se <strong>uvijek možete obratiti</strong>.
						<em> Gdje? ...u navigaciji, sekcija 'Podrška korisnicima'.</em>
					</li>
					<li className="m-b-100">
						<strong>Upotpunite podatke</strong> vaše firme (adresa, logo...).{" "}
						<em>Gdje? ...u navigaciji, sekcija 'Moja firma'.</em>
					</li>
					<li className="m-b-100">
						Ukoliko želite, postavite <strong>novu šifru/password</strong> po vašoj želji.{" "}
						<em>Gdje? ...u navigaciji, sekcija 'Moj profil'.</em>
					</li>
					<li className="m-b-100">
						Kreirajte vašu <strong>prvu ponudu</strong>, čisto da vidite kako će se uklopiti vaši podaci i vaš logo.
						<em>Gdje? ...u navigaciji, sekcija 'Ponude'.</em>
					</li>
					<li className="m-b-100">Uživajte u produktivnosti sa vašim novim programom.</li>
				</ol>
			</Text>
			<br />
			{/* <Subheading>
				Dobro došli, {userInfoState.name} {userInfoState.surname}
			</Subheading> */}
			<br />
			<Section>
				<img src={logo} alt="Kadalis logo" id="logo" className="home-logo" />
			</Section>
		</>
	);
};

export default UserHome;
