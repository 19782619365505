import { baseURL } from "common/config/settings";

const ENDPOINTS = {
	legalEntities: `${baseURL}/legal-entities`,
	legalEntitiesDropdown: `${baseURL}/legal-entities/dropdown`,
	createLegalEntity: `${baseURL}/legal-entities/create`,
	fieldsCreateLegalEntity: `${baseURL}/fields/legal-entities/create`,
	updateLegalEntity: `${baseURL}/legal-entities/update`,
	naturalPersons: `${baseURL}/natural-persons`,
	naturalPersonsDropdown: `${baseURL}/natural-persons/dropdown`,
	fieldsCreateNaturalPerson: `${baseURL}/fields/natural-persons/create`,
	createNaturalPerson: `${baseURL}/natural-persons/create`,
	updateNaturalPerson: `${baseURL}/natural-persons/update`,
	legalEntityTypes: `${baseURL}/legal-ent-types/all`,
	legalEntityTypesDropdown: `${baseURL}/legal-ent-types/dropdown`,
};

export default ENDPOINTS;
