import { IBtnGroupProps } from "common/interfaces/button_group";

const ButtonGroup = (props: IBtnGroupProps) => {
  return (
    <div className={`btn-group-container float-${props.itemsPosition} ${props.classes}`}>
      <div id={props.id} className={`btn-group ${props.groupWidth} cols-${props.nrOfCols}`}>
        {props.children}
      </div>
    </div>
  );
};

export default ButtonGroup;
