import Button from "common/components/btns/Button";
import Checkbox from "common/components/inputs/Checkbox";
import Dropdown from "common/components/inputs/Dropdown";
import InputField from "common/components/inputs/InputField";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { ICreateRowProps } from "common/interfaces/table";
import { formatDate } from "common/utils/formatDate";
import getFromNestedObject from "common/utils/getFromNestedObject";
import { useContext, useEffect, useState } from "react";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";

const getInputFieldProps = (key: string, attrs: any, defaultState: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object
	o.name = key;
	o.size = "md";
	o.disableClearBtn = true;
	o.autoComplete = false;
	defaultState && (o.defaultState = defaultState);
	// defaultVal && (o.value = defaultVal);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const getCheckboxProps = (key: string, attrs: any, defaultState: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object

	o.name = key;
	o.size = "md";
	o.includeDefaultValInData = false;
	defaultState && (o.defaultState = defaultState);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const inputField = (key: string, attrs: any, defaultState: any, dropdowns: any) => {
	let fieldProps: any = {};
	if (attrs.type === "boolean") {
		fieldProps = getCheckboxProps(key, attrs, defaultState);
	} else {
		fieldProps = getInputFieldProps(key, attrs, defaultState);
	}

	if (attrs.dropdown && dropdowns) {
		return <Dropdown {...fieldProps} items={dropdowns[key]} />;
	} else if (attrs.type === "boolean") {
		return <Checkbox {...fieldProps} />;
	} else if (attrs.format == "date") {
		return <InputField {...fieldProps} type="date" min={formatDate(Date.now(), "Y4+F-+M2+F-+D2")} />;
	} else if (attrs.type === "number" || attrs.type === "integer") {
		return (
			<>
				<InputField {...fieldProps} type="number" noArrows={true} />
			</>
		);
	} else if (attrs.type === "text") {
		return (
			<>
				<InputField {...fieldProps} isTextarea={true} />
			</>
		);
	} else {
		return (
			<>
				<InputField {...fieldProps} />
			</>
		);
	}
};

const Row = ({
	fieldsAttrs,
	sourceRowValues,
	dropdowns,
	funcsGetRowItem,
	classes,
	isLastRow,
	duplicateRow,
	deleteRow,
}: ICreateRowProps) => {
	const dataLevel: any = useContext(FormDataLevelContext);
	const [data, setData] = useContext(FormDataContext); // do not delete, we use this for duplicate row (temporarily disabled)
	const [oldValues, setOldValues]: any = useState({});
	const [defaultValues, setDefaultValues]: any = useState(sourceRowValues);
	const [popupNotif, setPopupNotif] = useContext(PopupNotificationsContext);

	const fillRowWithFetchedData = async (field_id: string, field_val: number) => {
		const result = await funcsGetRowItem[field_id](field_val);
		if (result.data) {
			setDefaultValues(() => result.data);
		} else {
			setPopupNotif((c: any) => [
				...c,
				{
					type: "error",
					msg: "Greška! Postojeće podatke nije moguće povući. Pokuštajte ponovo ili kontaktirajte vašeg administratora.",
					dateTime: Date.now(),
				},
			]);
		}
	};

	const fetchExistingDataAndFillRow = () => {
		// Check if a dropdown has changed value and has a
		// function to fetch existing data and fill the row with it.
		try {
			const rowValues = getFromNestedObject(data, dataLevel);
			for (const field_id in funcsGetRowItem) {
				if (Object.keys(rowValues).includes(field_id)) {
					const dropd_value = rowValues[field_id];
					if (dropd_value !== "") {
						if (!Object.keys(oldValues).includes(field_id) || dropd_value !== oldValues[field_id]) {
							// if it's first value (first time set) OR new value (second and more time set)
							fillRowWithFetchedData(field_id, dropd_value);
						}
					}
					setOldValues((c: any) => ({ ...c, [field_id]: dropd_value }));
					break;
				}
			}
		} catch (error) {
			// catch our custom err 'data-level not found in object'
		}
	};

	useEffect(() => {
		fetchExistingDataAndFillRow();
	}, [data]);

	return (
		<tr className={classes}>
			<td className="options-cell">
				{/* <Button
          size="xs"
          onClick={() => {
            if (dataLevel.length > 0) {
              duplicateRow(dataLevel);
            }
          }}
        >
          Dupliraj
        </Button> */}
				{!isLastRow && (
					<Button size="sm" color="danger" onClick={() => deleteRow(dataLevel)}>
						Obriši
					</Button>
				)}
			</td>
			{fieldsAttrs &&
				Object.entries(fieldsAttrs).map(([key, attrs]: any) => {
					return (
						<td key={key + "1"} className="data-cell">
							{inputField(key, attrs, typeof defaultValues === "object" && defaultValues[key], dropdowns)}
						</td>
					);
				})}
		</tr>
	);
};

export default Row;
