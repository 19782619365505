import { baseURL } from "common/config/settings";

let ENDPOINTS = {
	login: `${baseURL}/login-user`,
	myInfo: `${baseURL}/my-info`,
	fieldsRegisterUser: `${baseURL}/user/fields/register`,
	registerUser: `${baseURL}/user/register`,
	users: `${baseURL}/users`,
	usernames: `${baseURL}/usernames`,
	userStatusesDropdown: `${baseURL}/user-statuses/dropdown`,
	updateMyPassword: `${baseURL}/me/update/password`,
	deleteUser: `${baseURL}/user/delete`,
	activateUserByAdmin: `${baseURL}/user/activate`,
	deactivateUserByAdmin: `${baseURL}/user/deactivate`,
	updateUserInfoByAdmin: `${baseURL}/user/update`,
	updateUserPasswordByAdmin: `${baseURL}/user/update/password`,
	permissions: `${baseURL}/permissions/all`,
	permissionsCreateByAdmin: `${baseURL}/permissions/create`,
	fieldsPermissionsCreateByAdmin: `${baseURL}/permissions/fields/create`,
	permissionsUpdateByAdmin: `${baseURL}/permissions/update`,
	permissionsDeleteByAdmin: `${baseURL}/permissions/delete`,
};

export default ENDPOINTS;
