import DocsOutTable from "appdocsout/components/DocsOutTable";
import { DocTypeEnum } from "appdocsout/enums/docTypes";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const OrderFormsOut = () => {
  return (
    <>
      <Heading>Narudžbenice (izlazne)</Heading>

      <Section>
        <DocsOutTable docTypeId={DocTypeEnum.OrderForm} title="Lista izlaznih narudžbenica" />
      </Section>
    </>
  );
};

export default OrderFormsOut;
