import { createContext } from "react";


/**
 * HOW TO USE IT
 * 
 * just add data into the global object
 * 
 * IN FUTURE we will make an enum or somehting for define exactly the names and 
 * nesting levels of all data
 * 
 */

export const GlobalDataContext = createContext<any>({});

