import { createContext, useState } from "react";

/* 
Data Level means "the level of nesting in an object".
Example (3 levels of nesting: 1, 1.1, 1.1.1):
const data = {
    "attr-1":{
        "attr-1.1":{
            "attr-1.1.1": 0,
            "attr-1.1.2": 0,
        },
        "attr-2.1":{
            "attr-2.1.1": 0,
            "attr-2.1.2": 0,
        }
    }
}
 */

export const FormDataLevelContext = createContext<any>([]);

