import LoginForm from "applogin/components/LoginForm";
import { useContext, useEffect, useState } from "react";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";

const LoginPage = () => {
	const [popNotifState, setPopNotif] = useContext(PopupNotificationsContext);
	const [collapse, setCollapse] = useState(false);

	useEffect(() => {
		if (popNotifState.length > 0) {
			// CLEAR AND CLOSE POPUP NOTIFICATIONS (to avoid opening old notifications after login)
			setPopNotif([...[]]);
			setCollapse(false);
		}
	});

	return (
		<div className="login-container">
			<LoginForm />
		</div>
	);
};
export default LoginPage;
