import { Route } from "react-router-dom";
import CompanyInfo from "appcompany/views_private/CompanyInfo";

const CompanyRoutes = () => {
  return (
    <>
      <Route path="/company" element={<CompanyInfo />} />
    </>
  );
};

export default CompanyRoutes;
