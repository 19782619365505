import { baseURL } from "common/config/settings";

const prefix = "/items";

const ENDPOINTS = {
	item: `${baseURL}${prefix}/item`,
	items: `${baseURL}${prefix}/all`,
	itemsDropdown: `${baseURL}${prefix}/dropdown`,
	getDocumentPdfById: `${baseURL}${prefix}/documents/out/pdf/file`,
	fieldsCreateItem: `${baseURL}${prefix}/fields/create`,
	createItem: `${baseURL}${prefix}/create`,
	updateItem: `${baseURL}${prefix}/update`,
};

export default ENDPOINTS;
