import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { IDropdownProps } from "common/interfaces/dropdown";
import createNestedObject from "common/utils/createNestedObject";
import { useContext, useEffect, useRef, useState } from "react";
import FormDataLevelProvider from "../../contexts/FormDataLevelProvider";

/**
 * Use either defaultState (controlled component) or defaultValue (uncontrolled component)
 */

const getDefaultValue = (defaultValue: any) => {
  if (defaultValue) {
    return { defaultValue: defaultValue };
  } else {
    return {};
  }
};

const DropdownClassic = ({
  size,
  firstEmpty = true,
  defaultState,
  defaultValue,
  disabled,
  items,
  id,
  classes,
  name,
  onChange,
}: any) => {
  const [data, setData] = useContext(FormDataContext);
  const dataLevel: any = useContext(FormDataLevelContext);
  const [val, setVal] = useState();
  const ref = useRef<any>(null);

  const setDefault = (val: any) => {
    setVal(val);
    setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, name], val) }));
  };

  useEffect(() => {
    if (defaultState) {
      setDefault(defaultState); // set default state
    }
    // else if (!firstEmpty && items) {
    //   // this is still not ready. problem is that it puts default value for
    //   // records that should not be sent and will throw required fields error.
    //   setDefault(items[0].value); // set first element as default
    // }
  }, []);

  return (
    <div className={`dropdown-container size-${size} ${classes} ${disabled ? "disabled" : ""}`}>
      <select {...getDefaultValue(defaultValue)} disabled={disabled} ref={ref} name={name} id={id} value={val} onChange={onChange}>
        {firstEmpty && (
          <option key="-" value="">
            -
          </option>
        )}
        {items &&
          items.map(({ value, text }: any) => {
            return (
              <option key={value} value={value}>
                {text}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default DropdownClassic;
