import Heading from "common/components/text/Heading";
import Subheading from "common/components/text/Subheading";
import { Link, useLocation } from "react-router-dom";

const DeciderPage = () => {
  const location = useLocation();
  const props: any = location.state;

  return (
    <div className="decider">
      <Heading>{props.title}</Heading>

      <div className="container">
        {props.items &&
          Object.keys(props.items).map((categoryText: string, index: any) => {
            return (
              <div key={index} className="category-container">
                <Subheading>{categoryText}</Subheading>
                {Object.keys(props.items[categoryText]).map((linkText: string, index: any) => {
                  return (
                    <div key={index} className="item">
                      <Link to={props.items[categoryText][linkText]} onClick={props.onClick}>
                        {linkText}
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DeciderPage;
