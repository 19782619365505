import Resizer from "common/components/tables/base_common/Resizer";

const HeaderRow = ({ classes, id, fieldsAttrs }: any) => {
	return (
		<tr key="header" className={`header-row ${classes}`} id={id}>
			<th key="options" className="header-cell options-cell">
				opcije
			</th>
			{Object.entries(fieldsAttrs).map(([key, attrs]: any) => {
				return (
					<th key={key} className={`header-cell width-${attrs.width}`}>
						<Resizer width={key !== "selection" && attrs.width} />
						{attrs.required && "*"}
						{attrs.title}
					</th>
				);
			})}
		</tr>
	);
};

export default HeaderRow;
