import Button from "common/components/btns/Button";
import PopupNotificationSmall from "common/components/notifications/PopupNotificationSmall";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";
import { useContext, useEffect, useState } from "react";

const PopupNotificationsZone = ({ children }: any) => {
  const [popNotifState, setPopNotif] = useContext(PopupNotificationsContext);
  const [collapse, setCollapse] = useState(false);

  const handleCloseNotification = () => {
    setPopNotif([...[]]);
    setCollapse(false);
  };

  const handleExpandCollapse = () => {
    setCollapse(!collapse);
  };

  useEffect(() => {
    setCollapse(false);
  }, [popNotifState]);

  return (
    <section id="zone-popup-notification" className={`${collapse && "collapse"}`}>
      {popNotifState.length > 0 && (
        <div className="manage-notifications-container">
          <Button
            classes={"btn-show-hide-notifications"}
            layout="icon-left"
            icon={collapse ? "expand" : "collapse"}
            size="sm"
            onClick={handleExpandCollapse}
          >
            {collapse ? "Pokaži" : "Smanji"}
          </Button>
          <Button
            classes={"btn-close-notifications"}
            layout="icon-left"
            icon="close"
            size="sm"
            color="danger"
            onClick={handleCloseNotification}
          >
            Zatvori sve
          </Button>
        </div>
      )}
      {[...popNotifState].reverse().map((val: any, index: number) => {
        return (
          <PopupNotificationSmall
            key={popNotifState.length - index - 1}
            listIndex={popNotifState.length - index - 1}
            notifType={val.type}
            msg={val.msg}
            dateTime={val.dateTime}
          />
        );
      })}
    </section>
  );
};

export default PopupNotificationsZone;
