const deleteFromNestedObject = (existingObject: any, levelsList: string[]) => {
  /**
   * 1) levelsList is the path in the object where you want to store a finalValue
   *    example:
   *    levelsList = ["vehicle", "car", "audi"]
   *    will do: delete vehicle.car.audi
   *
   * 2) Function does NOT modify the passed object, instead it modifies and returns a new one.
   *
   * 3) Function checks a value if IS NOT A JSON OBJECT (like string, number, function, array...)
   *        to avoid further nesting and throws error. Example:
   *    - obj = {car: "audi"} (this cannot be further nested as it is a string "audi" not an {})
   *          example: obj.car.audi returns ERROR because "audi" is a string, not an object.
   *    - It throws ERROR on following situations:
   *      let myOb = {
   *        L1: "some primitive",
   *        L2: function(){},
   *        L3: new Array(),
   *      }
   *    OR if NO MORE LEVELS than expected.
   *
   */

  let copiedObj = { ...existingObject };
  let counter = 0;

  const findLevelsThenDeleteLevel = (obj: any, levels: string[]) => {
    // if last level, remove the level
    if (counter === levels.length - 1) {
      delete obj[levels[counter]];
      counter++;
    }
    // if more levels on list continue diving into object
    if (counter < levels.length - 1) {
      let nextLevel = obj[levels[counter]];
      if (nextLevel) {
        // if not an object CANCEL (user expected an object, but it is not)
        if (typeof nextLevel !== "object" || Array.isArray(nextLevel) || nextLevel === null) {
          throw "Not an object. You expected this to be an object, but it is not";
          // return
        }
        counter++;
        findLevelsThenDeleteLevel(nextLevel, levels);
      } else {
        // if no more levels CANCEL (user expected more levels)
        throw "No more levels. You expected more levels";
        // return
      }
    }
    return;
  };

  if (levelsList.length > 0) {
    // start recursion
    findLevelsThenDeleteLevel(copiedObj, levelsList);
  }
  return copiedObj;
};

export default deleteFromNestedObject;
