import MainMenuZone from "common/components/layout_zones/MainMenuZone";
import SearchBar from "common/components/nav_top/SearchBar";
import Button from "common/components/btns/Button";
import ProfileMenu from "./ProfileMenu";
import Popover from "common/components/popovers/Popover";
import { useContext } from "react";
import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { Link, useNavigate } from "react-router-dom";
import { GlobalDataContext } from "common/contexts/GlobalDataContext";

const TopNav = () => {
	const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
	const navigate = useNavigate();
	const [gData, setGData] = useContext(GlobalDataContext);

	return (
		<>
			{/* <div className={`zone-left ${gData.isMenuLeft ? "" : "nav-items-right"}`} > */}
			<div className={`zone-left nav-items-right`}>
				<Popover
					layout="icon-left"
					size="lg"
					icon="menu"
					iconActive="close"
					// classes={`main-menu ${gData.isMenuLeft ? "" : "menu-right"}`}
					classes={`main-menu menu-right`}
					btnContent="Navigacija"
				>
					<MainMenuZone />
				</Popover>
				<Link to="" onClick={(e: any) => e.preventDefault()}>
					<Button size="lg" layout="icon-left" icon="left" color="transparent" onClick={() => navigate(-1)}>
						Nazad
					</Button>
				</Link>
			</div>
			<div className="zone-middle">
				<Button size="md" layout="icon-only" icon="search" classes="btn-search"></Button>
				<SearchBar />
			</div>
			<div className="zone-right">
				{/* <Link to="/user/support">
          <Button size="lg" color="transparent">
            Pomoć
          </Button>
        </Link>
        <Popover layout="icon-only" size="lg" icon="notification" classes="notifications">
          <ProfileMenu />
        </Popover> */}
				{/* <Popover layout="icon-right" size="lg" icon="expand" classes="profile-menu" btnContent={userInfoState.name}>
          <ProfileMenu />
        </Popover> */}
			</div>
		</>
	);
};

export default TopNav;
