import Icons from "common/components/icons/Icons";
import { IZoneContentProps } from "common/interfaces/input_field_block";

const ZoneContent = (props: any) => {
  // zonePosition can be: zonePosition, "b", "l", or "r" (top, bottom, left or right)
  let notifType = "";
  let notifIcon = "";

  if (!props.notifType) {
    notifType = "";
    notifIcon = "clear";
  } else if (props.notifType === "success") {
    notifType = "success";
    notifIcon = "notification";
  }

  return (
    <div className={`zone-content icon-text-block size-${props.size}`}>
      {props.titlePos === props.zonePosition && props.iconLeft && (
        <div className="zone-left">
          <div className={`icon ${props.iconBg}`}>
            <Icons icon={props.iconLeft} />
          </div>
        </div>
      )}
      <div className="content">
        {props.titlePos === props.zonePosition && props.title && (
          <>
            <p className="block-title">{props.title}</p>
            {props.descr && <p className="block-description">{props.descr}</p>}
          </>
        )}
        {props.infoPos === props.zonePosition && props.info && <p className="block-info">{props.info}</p>}
        {props.notifPos === props.zonePosition && props.notif && (
          <p className={`block-notification ${notifType}`}>
            <span>
              <Icons icon={notifIcon} />
            </span>
            {props.notif}
          </p>
        )}
      </div>
      {props.titlePos === props.zonePosition && props.iconRight && (
        <div className="zone-right">
          <div className={`icon ${props.iconBg}`}>
            <Icons icon={props.iconRight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoneContent;
