const createErrMsgFromFastAPIResponse = (errDetail: any) => {
	let msg = "";
	errDetail.forEach((el: any) => {
		let errPath = el.loc.join(" -> "); // example: body -> username
		msg += `${errPath} = "${el.msg}"\n`;
	});
	return msg;
};

const getMsgFromHttpErrors = (error: any) => {
	if (error) {
		if (!error.response) {
			return "Greška se desila. Molimo pokušajte ponovo ili kontaktirajte vašeg admina.";
			// return `Some error occured. Please try again or contact your admin.`; ##translate
		}
		const res = error.response;
		const resData = res.data
		let errDetail
		if(resData instanceof ArrayBuffer){
			const decoder = new TextDecoder();
			const decoded_response: any = decoder.decode(error.response.data);
			errDetail = JSON.parse(decoded_response).detail
		}else{
			errDetail = resData.detail;
		}
		switch (res.status) {
			case 499: // OUR CUSTOM FACADE STATUS CODE ('ERR_GENERAL CODE')
				return errDetail;
			case 123: // OUR CUSTOM FRONTEND STATUS CODE
				return errDetail;
			case 400:
				return errDetail;
			case 401:
				return errDetail;
			case 422:
				if (errDetail && errDetail[0].loc && errDetail[0].msg) {
					return createErrMsgFromFastAPIResponse(errDetail);
				} else {
					return "Neispravni podaci."; // "Unprocessable entity"; ##translate
				}
			default: // any other response error
				return `Greška se desila. ID[${res.status}]`; // `Some error occured. ID[${res.status}]`; ##translate
		}
	}
};

export default getMsgFromHttpErrors;
