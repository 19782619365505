import Table from "./Table";
import { ITableUpdateProps } from "common/interfaces/table";

const TableUpdate = ({
	blockId,
	columns,
	tableData,
	dropdowns,
	fieldsAttrs,
	rowBtnsProps,
	setterColumnSort,
	currSortCol,
	sortDesc,
	setterPageNr,
	setterPageSize,
	customPageNr,
}: ITableUpdateProps) => {
	return (
		<Table
			isUpdate={true}
			blockId={blockId}
			columns={columns}
			data={tableData}
			fieldsAttrs={fieldsAttrs}
			dropdowns={dropdowns}
			rowBtnsProps={rowBtnsProps}
			editActive={true}
			skipPageReset={false}
			setterColumnSort={setterColumnSort}
			currSortCol={currSortCol}
			sortDesc={sortDesc}
			setterPageNr={setterPageNr}
			setterPageSize={setterPageSize}
			customPageNr={customPageNr}
		/>
	);
};

export default TableUpdate;
