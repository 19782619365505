import { Route } from "react-router-dom";
import Items from "appitems/views_private/Items";
import ItemsCreate from "appitems/views_private/ItemsCreate";

const ItemsRoutes = () => {
  return (
    <>
      <Route path="/items" element={<Items />} />
      <Route path="/items/create" element={<ItemsCreate />} />
    </>
  );
};

export default ItemsRoutes;
