import { baseURL, baseURLUpload } from "common/config/settings";

const prefix = "/admin/my-companies";

const ENDPOINTS_ADMIN = {
	myCompanies: `${baseURL}${prefix}/all`,
	createCompany: `${baseURL}${prefix}/companies/create`,
	fieldsCreateCompany: `${baseURL}${prefix}/fields/companies/create`,
	updateCompany: `${baseURL}${prefix}/companies/update`,
	uploadLogo: `${baseURLUpload}${prefix}/upload/logo`,
	getLogo: `${baseURL}${prefix}/company-logo`,
};

export default ENDPOINTS_ADMIN;
