const createNestedObject = (existingObject: any, levelsList: string[], finalValue: any) => {
  /**
   * 1) levelsList is the path in the object where you want to store a finalValue
   *    example:
   *    levelsList = ["vehicle", "car", "audi"]
   *    finalValue = "A8"
   *    will do: vehicle.car.audi = "A8"
   *
   * 2) Function does NOT modify the passed object, instead it modifies and returns a new one.
   *
   * 3) Function overrides a value that IS NOT A JSON OBJECT (like string, number, function, array...)
   *        to enable further nesting. Example:
   *    - obj = {car: "audi"} (this cannot be further nested as it is a string "audi" not an {})
   *          example: obj.car.audi = "A8", returns ERROR because "audi" is a string, not an object.
   *    - SOLUTION: the code replaces "audi" with and empty object {} ({car: {}}) and that
   *          will enable us to create {car: {audi: "A8"}}
   *    - It replaces following situations to an empty object {}:
   *      let myOb = {
   *        L1: "some primitive",
   *        L2: function(){},
   *        L3: new Array(),
   *
   */

  let copiedObj = { ...existingObject };
  let counter = 0;

  const findOrAddLevelsThenAddValue = (obj: any, levels: string[]) => {
    // if last level, add value
    if (counter === levels.length - 1) {
      obj[levels[counter]] = finalValue;
      counter++;
    }
    // if more levels on list continue diving into object
    if (counter < levels.length - 1) {
      let nextLevel = obj[levels[counter]];
      if (nextLevel) {
        // if not an object, replace with object to be able to continue nesting
        if (typeof nextLevel !== "object" || Array.isArray(nextLevel) || nextLevel === null) {
          nextLevel = obj[levels[counter]] = {};
        }
        counter++;
        findOrAddLevelsThenAddValue(nextLevel, levels);
      } else {
        const newLevelCreated = (obj[levels[counter]] = {});
        counter++;
        findOrAddLevelsThenAddValue(newLevelCreated, levels);
      }
    }
    return;
  };

  if (levelsList.length > 0) {
    // start recursion
    findOrAddLevelsThenAddValue(copiedObj, levelsList);
  }
  return copiedObj;
};

export default createNestedObject;
