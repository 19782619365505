import Heading from "common/components/text/Heading";
import Section from "common/components/layout_zones/Section";
import Form from "common/components/forms/base/Form";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Button from "common/components/btns/Button";
import InputGroup from "common/components/inputs/InputGroup";
import InputFieldBlock from "common/components/inputs/InputFieldBlock";
import Subheading from "common/components/text/Subheading";
import InputFieldClassic from "common/components/inputs/InputFieldClassic";
import { useEffect, useState } from "react";
import { updateMyPassword } from "applogin/api/users";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";

const UserProfile = () => {
	const [val, setVal]: any = useState("");
	const [resultFromSubmit, sub] = useSubmitterAsync();

	const submitData = () => {
		sub(() =>
			updateMyPassword({
				password: val,
			})
		);
	};

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit) {
			setVal("");
		}
	}, [resultFromSubmit]);

	return (
		<>
			<Heading>Moj profil</Heading>

			<Section>
				<Subheading>Promjena passworda</Subheading>
				<Form>
					<InputGroup>
						<InputFieldBlock size="md" title="Novi password" titlePos="l">
							<InputFieldClassic
								size="md"
								required={true}
								disableClearBtn={true}
								value={val}
								onChange={(e: any) => setVal(e.target.value)}
							/>
						</InputFieldBlock>
						<ButtonGroup itemsPosition="r">
							<Button size="md" disabled={val === ""} onClick={() => submitData()}>
								OK
							</Button>
						</ButtonGroup>
					</InputGroup>
				</Form>
			</Section>
		</>
	);
};

export default UserProfile;
