import DocsOutTable from "appdocsout/components/DocsOutTable";
import { DocTypeEnum } from "appdocsout/enums/docTypes";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const InvoicesOut = () => {
  return (
    <>
      <Heading>Fakture (izlazne)</Heading>

      <Section>
        <DocsOutTable docTypeId={DocTypeEnum.Invoice} title="Lista izlaznih faktura" />
      </Section>
    </>
  );
};

export default InvoicesOut;
