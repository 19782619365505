import { useContext, useState } from "react";
import { loginGetToken } from "applogin/api/login";
import { IUserInfoFetched, IUserInfo } from "applogin/interfaces/users";
import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import tokenUtils from "applogin/utils/token";
import { getOnlyCommonAttrs } from "utils";
import { useNavigate } from "react-router-dom";
import Button from "common/components/btns/Button";
import logo from "assets/icons/logo.svg";
import InputFieldBlock from "common/components/inputs/InputFieldBlock";
import LoaderSmall from "common/components/loaders/LoaderSmall";
import { getMyInfo } from "applogin/api/users";
// import { baseURL } from "common/config/settings";
// import baseURLUtils from "applogin/utils/baseURL";
// import { checkBaseURL } from "applogin/api/_baseURL";
import InputFieldClassic from "common/components/inputs/InputFieldClassic";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";

const LoginForm = () => {
	const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
	const [companyUsername, setCompanyUsername] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [resultMessage, setResultMessage] = useState("");
	const [loaderSpinner, setLoaderSpinner] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setResultMessage("");
		setLoaderSpinner(true);

		try {
			// const baseURLExists = await checkBaseURL(baseURL);

			// if (baseURLExists) {
			// baseURLUtils.saveLocalBaseURL(baseURL);
			try {
				const result = await loginGetToken(companyUsername, username, password);
				const token = result.data.access_token;
				const tut = result.data.tut;
				tokenUtils.saveLocalToken(token);
				tokenUtils.saveLocalTUT(tut);

				const result2 = await getMyInfo();
				const fetchedUserInfo: IUserInfoFetched = result2.data;
				const adjustedUserInfo: IUserInfo = getOnlyCommonAttrs(fetchedUserInfo, userInfoState);
				let newUserInfoState: IUserInfo = {
					...userInfoState,
					...adjustedUserInfo,
					isAdmin: fetchedUserInfo.is_admin,
					isAuthenticated: true,
					token: token,
					tut: tut,
				};
				setUserInfoState({ ...newUserInfoState });
				setLoaderSpinner(false);
				navigate("/user/home");
			} catch (error: any) {
				tokenUtils.removeLocalToken();
				tokenUtils.removeLocalTUT();
				// baseURLUtils.removeLocalBaseURL();
				setLoaderSpinner(false);
				setResultMessage(getMsgFromHttpErrors(error));
				// setResultMessage("Wrong username or password"); ##trans
			}
			// }
		} catch (error: any) {
			setLoaderSpinner(false);
			setResultMessage("Desila se greška. Pokušajte ponovo ili kontaktirajte administratora.");
			// if (error.response && error.response.status == 400) {
			// 	setResultMessage("Pogrešna šifra firme.");
			// } else {
			// 	setResultMessage("Desila se greška. Pokušajte ponovo ili kontaktirajte administratora.");
			// }
			// setResultMessage("Wrong company code"); ##trans
		}
	};

	return (
		<div className="login-block">
			<img src={logo} alt="Kadalis logo" id="logo" className="m-b-030" />
			<form onSubmit={handleSubmit}>
				<h3 className="m-t-000 m-b-030">Login</h3>
				<p className="m-t-000 m-b-100">Unesite vaše podatke</p>
				{loaderSpinner && (
					<div className="login-loader m-t-000 m-b-100">
						<LoaderSmall />
					</div>
				)}
				<p id="error" className="m-t-000 m-b-100">
					{resultMessage}
				</p>
				<InputFieldBlock size="lg" title="" titlePos="t">
					<InputFieldClassic
						disableClearBtn={true} // clear does not work here. Needs to be fixed.
						size="lg"
						placeholder="korisničko ime firme"
						onChange={(e: any) => {
							setCompanyUsername(e.target.value);
						}}
					/>
				</InputFieldBlock>
				<br />
				<InputFieldBlock size="lg" title="" titlePos="t">
					<InputFieldClassic
						disableClearBtn={true} // clear does not work here. Needs to be fixed.
						size="lg"
						placeholder="vaše korisničko ime"
						onChange={(e: any) => {
							setUsername(e.target.value);
						}}
					/>
				</InputFieldBlock>
				<br />
				<InputFieldBlock size="lg" title="" titlePos="t">
					<InputFieldClassic
						disableClearBtn={true} // clear does not work here. Needs to be fixed.
						id="password"
						size="lg"
						placeholder="vaš password"
						type={showPassword ? "text" : "password"}
						onChange={(e: any) => {
							setPassword(e.target.value);
						}}
					>
						<Button
							type="button"
							size="lg"
							layout="icon-only"
							icon={showPassword ? "hide" : "show"}
							onClick={() => setShowPassword(!showPassword)}
						></Button>
					</InputFieldClassic>
				</InputFieldBlock>
				<br />
				<br />
				<Button type="submit" size="lg" color="danger" classes="w-100">
					Prijavi se
				</Button>
			</form>
		</div>
	);
};

export default LoginForm;
