import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Icons from "../icons/Icons";

const UserMenuLinks = (props: any) => {
	const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

	const navItemsCommon: any = {
		"Početna": "/user/home",
		"Podrška korisnicima": "/user/support",
		"Moj profil": "/user/profile",
		"Moja firma": "/company",
	};

	const navItemsCommonDecider: any = {
		"Lica (pravna/fizička)": {
			Opcije: {
				Lista: "/associates",
				Dodaj: "/associates/create",
			},
		},
		Stavke: {
			Opcije: {
				Lista: "/items",
				Dodaj: "/items/create",
			},
		},
	};

	// const navItemsWarehouse: any = {
	//   Stavke: {
	//     Lista: "/items",
	//     Dodaj: "/items/create",
	//   },
	// };

	const navItemsFinance: any = {
		Upiti: {
			Izlazni: {
				Lista: "/docs-out/inquiries",
				Dodaj: "/docs-out/inquiry/create",
			},
		},
		Ponude: {
			Izlazne: {
				Lista: "/docs-out/estimates",
				Dodaj: "/docs-out/estimate/create",
			},
		},
		Predračuni: {
			Izlazni: {
				Lista: "/docs-out/quotes",
				Dodaj: "/docs-out/quote/create",
			},
		},
		Narudžbenice: {
			Izlazne: {
				Lista: "/docs-out/order-forms",
				Dodaj: "/docs-out/order-form/create",
			},
		},
		"Avansne fakture": {
			Izlazne: {
				Lista: "/docs-out/advance-invoices",
				Dodaj: "/docs-out/advance-invoice/create",
			},
		},
		Fakture: {
			Izlazne: {
				Lista: "/docs-out/invoices",
				Dodaj: "/docs-out/invoice/create",
			},
		},
	};

	return (
		<>
			<div className="category">
				{/* <div className="user-zone">
          <h5 className="item">
            <Icons icon="profile" /> alispahicamel@hotmail.com
          </h5>
        </div> */}
				<h4 className="title"></h4> {/* Just to hold spacing */}
				<h5 className="email">{userInfoState.email}</h5>
				<h6 className="title">
					Profil
				</h6>
        {/* <h6 style={{"color":"transparent"}}>-</h6> */}
				{Object.keys(navItemsCommon).map((linkText: string, index: any) => {
					return (
						<h5 key={index} className="item">
							<Link to={navItemsCommon[linkText]} onClick={props.onClick}>
								{linkText}
							</Link>
						</h5>
					);
				})}
				<h6 className="title">Opšte</h6>
        {/* <h6 style={{"color":"transparent"}}>-</h6> */}
				{Object.keys(navItemsCommonDecider).map((linkText: string, index: any) => {
					return (
						<h5 key={index} className="item">
							<Link
								to="/decider"
								onClick={props.onClick}
								state={{ title: linkText, items: navItemsCommonDecider[linkText] }}
							>
								{linkText}
							</Link>
						</h5>
					);
				})}
				<h6 className="title">Dokumenti</h6>
        {/* <h6 style={{"color":"transparent"}}>-</h6> */}
				{Object.keys(navItemsFinance).map((linkText: string, index: any) => {
					return (
						<h5 key={index} className="item">
							<Link to="/decider" onClick={props.onClick} state={{ title: linkText, items: navItemsFinance[linkText] }}>
								{linkText}
							</Link>
						</h5>
					);
				})}
			</div>
		</>
	);
};

export default UserMenuLinks;
