import axios from "axios";
import ENDPOINTS from "appunits/api/endpoints";
import tokenUtils from "applogin/utils/token";

export const getCurrencies = async () => {
  return await axios.get(ENDPOINTS.currencies, tokenUtils.addTokenToAuthHeaders());
};

export const getCurrenciesDropdown = async () => {
  return await axios.get(ENDPOINTS.currenciesDropdown, tokenUtils.addTokenToAuthHeaders());
};
