import Icons from "common/components/icons/Icons";
import { ILoaderSmallAndErrorProps } from "common/interfaces/loader";
import { useNavigate } from "react-router-dom";
import Button from "../btns/Button";
import ButtonGroup from "../btns/ButtonGroup";

const LoaderSmallAndError = ({ children, errMsg, align, loaderTxt, funcTryAgain }: ILoaderSmallAndErrorProps) => {
  const navigate = useNavigate();
  if (errMsg) {
    return (
      <div className={`loader error align-${align}`}>
        <div className="content">
          <Icons icon="error" /> {errMsg}
        </div>
        <ButtonGroup itemsPosition="c">
          <Button size="sm" layout="icon-left" icon="question" onClick={() => navigate("/user/support")}>
            Kontaktiraj admina
          </Button>
          <Button size="sm" layout="icon-left" icon="refresh" onClick={() => funcTryAgain()}>
            Pokušaj ponovo
          </Button>
          {children}
        </ButtonGroup>
      </div>
    );
  }

  return (
    <div className={`loader align-${align}`}>
      <div className="loader-container loader-small">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <span>{loaderTxt ? loaderTxt : "Molimo sačekajte ..."}</span>
    </div>
  );
};

export default LoaderSmallAndError;
