import LoaderSmallAndError from "common/components/loaders/LoaderSmallAndError";

const TablePlaceholder = ({ errMsg, funcTryAgain }: any) => {
  return (
    <div className="table-wrapper table-placeholder-wrapper">
      <table className="table">
        <thead>
          <tr className="header-row">
            <th className="header-cell">col 1</th>
            <th className="header-cell">col 2</th>
            <th className="header-cell">col 3</th>
            <th className="header-cell">....</th>
            <th className="header-cell">....</th>
            <th className="header-cell">....</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="data-cell" colSpan={100}>
              <LoaderSmallAndError align="center" errMsg={errMsg} funcTryAgain={funcTryAgain}></LoaderSmallAndError>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TablePlaceholder;
