import useWindowSize from "common/hooks/useWindowSize";
import { IInputFieldBlockProps } from "common/interfaces/input_field_block";
import { useEffect, useState } from "react";
import ZoneContent from "./base_input_field_block/ZoneContent";

const InputFieldBlock = (props: IInputFieldBlockProps) => {
  const [width, height] = useWindowSize();
  const [gridType, setGridType] = useState("grid-1"); // one col

  const allPositions = new Set();
  allPositions.add(props.titlePos);
  allPositions.add(props.infoPos);
  allPositions.add(props.notifPos);

  useEffect(() => {
    if (allPositions.has("l") && allPositions.has("r")) {
      setGridType("grid-3");
    } else if (allPositions.has("l") || allPositions.has("r")) {
      setGridType("grid-2");
    }
    if (width < 600) {
      setGridType("grid-1"); // if smartphone then one grid
    }
  }, [width]);

  return (
    <div className={`input-field-block ${gridType}`}>
      {allPositions.has("l") && allPositions.has("t") && <div className="zone-blocked"></div>}
      {allPositions.has("t") && <ZoneContent zonePosition="t" {...props} />}
      {allPositions.has("r") && allPositions.has("t") && <div className="zone-blocked"></div>}
      {allPositions.has("l") && <ZoneContent zonePosition="l" {...props} />}
      <div className="zone-input">{props.children}</div>
      {allPositions.has("r") && <ZoneContent zonePosition="r" {...props} />}
      {allPositions.has("l") && allPositions.has("b") && <div className="zone-blocked"></div>}
      {allPositions.has("b") && <ZoneContent zonePosition="b" {...props} />}
      {allPositions.has("r") && allPositions.has("b") && <div className="zone-blocked"></div>}
    </div>
  );
};

export default InputFieldBlock;
