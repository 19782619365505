import { createNaturalPerson, getFieldsCreateNaturalPerson } from "appassociates/api/associates";
import { getCountriesDropdown } from "appunits/api/countries";
import Subheading from "common/components/text/Subheading";
import FormAuto from "common/components/forms/FormAuto";

const NaturalPersonAddForm = () => {
	const blockId = "natural_person_add";

	const funcsGetDropdowns = {
		country_code_iso: getCountriesDropdown,
	};

	return (
		<>
			<Subheading>Dodaj pravno lice:</Subheading>
			<FormAuto
				blockId={blockId}
				funcGetFieldsAttrs={getFieldsCreateNaturalPerson}
				funcsGetDropdowns={funcsGetDropdowns}
				funcSubmit={createNaturalPerson}
			></FormAuto>
		</>
	);
};

export default NaturalPersonAddForm;
