const City = () => {
  return (
    <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m473.901 482v-160.028h-172.357v160.028h-30v-190.028h101.179v-171.094h-33.726v-67.031h-38.163v-53.847h-30v53.847h-38.164v67.031h-33.726v361.122h-30v-294.09h-130.845v294.09h-38.099v30h512v-30zm-169.552-324.09h30v30h-30zm0 67.031h30v30h-30zm-186.49 245.294h-30v-30h30zm0-71.765h-30v-30h30zm0-71.764h-30v-30h30zm0-71.765h-30v-30h30zm149.459 0h-30v-30h30zm0-67.031h-30v-30h30zm3.515-97.032h30v30h-30zm98.164 391.122h-30v-120.4h30zm67.452 0h-30v-120.4h30z" />
      </g>
    </svg>
  );
};

export default City;
