import Heading from "common/components/text/Heading";
import UserRegisterForm from "appadmin/components/UserRegisterForm";
import UsersTable from "appadmin/components/UsersTable";
import UserPasswordsTable from "appadmin/components/UserPasswordsTable";
import Section from "common/components/layout_zones/Section";

const AdminUsers = () => {
	return (
		<>
			<Heading>Korisnici</Heading>

			<Section>
				<UserRegisterForm />
			</Section>

			<Section>
				<UsersTable />
			</Section>

			<Section>
				<UserPasswordsTable />
			</Section>
		</>
	);
};

export default AdminUsers;
