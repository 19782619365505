import { FormDataContext } from "common/contexts/FormDataContext";
import { useState } from "react";

const FormDataProvider = ({ children }: any) => {
  const [data, setData] = useState({});

  /**
   * HOW TO ADD VALUES:
   *
   * we need createNestedObject() function !!!
   *
   * const [dataContx, setDataContx] = useContext(FormDataContext);
   * setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, blockId], selectedDatabaseIds) }));
   *
   */

  return (
    <>
      <FormDataContext.Provider value={[data, setData]}>{children}</FormDataContext.Provider>
    </>
  );
};

export default FormDataProvider;
