import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "common/views_public/LoginPage";

const PublicRoutes = () => {
  const [userInfoState] = useContext(UserInfoContext);

  if (userInfoState.isAuthenticated) {
    return <></>;
  } else {
    return (
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    );
  }
};

export default PublicRoutes;
