import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import { useEffect } from "react";

const PDFContainer = ({ data, fileName = "pdf_file" }: any) => {
	const dataURL = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));

	const downloadFileWithName = () => {
		const anchor = document.createElement("a");
		anchor.download = fileName + ".pdf";
		anchor.href = dataURL;
		anchor.dataset.downloadurl = ["text/plain", anchor.download, anchor.href].join(":");
		anchor.click();
	};

	useEffect(() => {
		return () => window.URL.revokeObjectURL(dataURL); // clean-up memory on unmount
	}, []);

	return (
		<>
			<ButtonGroup classes="pdf-options" itemsPosition="l">
				<Button classes="download" size="sm" layout="icon-left" icon="download" onClick={downloadFileWithName}>
					Spremi
				</Button>
			</ButtonGroup>

			<object type="application/pdf" data={`${dataURL}`} width="100%" height="100%">
				<br />
				<br />
				<p className="t-center" style={{ paddingTop: "30%" }}>
					<a href={dataURL} download>
						<Button size="md">Klikni ovdje da otvoriš dokument</Button>
					</a>
				</p>
				{/* <iframe src={dataURL} width="100%" height="100%"></iframe> */}
			</object>
		</>
	);
};

export default PDFContainer;
