import { useContext, useEffect, useState } from "react";
import MainMenu from "common/components/nav_top/MainMenu";
import PopoverCardMiddle from "common/components/popovers/PopoverCardMiddle";
import tokenUtils from "applogin/utils/token";
import { USER_CONSTS } from "applogin/constants/userConsts";
import Icons from "../icons/Icons";
import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { GlobalDataContext } from "common/contexts/GlobalDataContext";

const MainMenuZone = () => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);
  const [gData, setGData] = useContext(GlobalDataContext);

  const handleClick = () => {
    tokenUtils.removeLocalToken();
    tokenUtils.removeLocalTUT();
    setUserInfoState(USER_CONSTS.initialUserInfo); //destroy the logged in object
  };

  useEffect(() => {
    if (document.getElementsByClassName("main-menu").length > 0) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <PopoverCardMiddle>
      {/* <div className="sidebar" onClick={() => setGData((o: any) => ({ ...o, ["isMenuLeft"]: !o.isMenuLeft }))}> */}
      <div className="sidebar" onClick={() => setGData((o: any) => ({ ...o, ["isMenuLeft"]: true }))}>
        {/* <div className="banner">Kadalis ERP</div> */}
        <div onClick={handleClick} className="banner">
          <Icons icon="close" /> Odjava{" "}
        </div>
      </div>
      <MainMenu />
    </PopoverCardMiddle>
  );
};

export default MainMenuZone;
