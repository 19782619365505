import { Route } from "react-router-dom";
import Associates from "appassociates/views_private/Associates";
import AddAssociate from "appassociates/views_private/AddAssociate";

const AssociatesRoutes = () => {
  return (
    <>
      <Route path="/associates" element={<Associates />} />
      <Route path="/associates/create" element={<AddAssociate />} />
    </>
  );
};

export default AssociatesRoutes;
