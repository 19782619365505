import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UserRoutes from "applogin/routes/UserRoutes";
import DocsOutRoutes from "appdocsout/routes/DocsOutRoutes";
import ItemsRoutes from "appitems/routes/ItemsRoutes";
import AssociatesRoutes from "appassociates/routes/AssociatesRoutes";
import CompanyRoutes from "appcompany/routes/CompanyRoutes";
import DeciderPage from "common/views_private/DeciderPage";

const PrivateRoutes = () => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

  if (userInfoState.isAuthenticated) {
    return (
      <Routes>
        {UserRoutes()}
        {CompanyRoutes()}
        {AssociatesRoutes()}
        {ItemsRoutes()}
        {DocsOutRoutes()}
        <Route path="/decider" element={<DeciderPage />} />
        <Route path="*" element={<Navigate to={"/user/home"} />} />
      </Routes>
    );
  }
  return <></>;
};

export default PrivateRoutes;
