const Ok = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 512.008 512.008">
      <g>
        <g>
          <path
            d="M502.795,60.572c-11.183-9.782-28.214-8.677-38.023,2.533L177.837,391.028L46.603,251.036
			c-10.186-10.833-27.217-11.372-38.077-1.213c-10.86,10.159-11.426,27.244-1.24,38.104l151.579,161.684
			c5.12,5.416,12.207,8.488,19.672,8.488h0.458c7.626-0.108,14.794-3.449,19.833-9.189L505.355,98.595
			C515.137,87.385,514.005,70.381,502.795,60.572z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Ok;
