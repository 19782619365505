import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Form from "common/components/forms/base/Form";
import InputFieldBlock from "common/components/inputs/InputFieldBlock";
import InputGroup from "common/components/inputs/InputGroup";
import LoaderSmallAndError from "common/components/loaders/LoaderSmallAndError";
import FormDataLevelProvider from "common/contexts/FormDataLevelProvider";
import { inputField } from "common/components/forms/FormAuto";
import { useContext, useEffect, useState } from "react";
import { FormDataContext } from "common/contexts/FormDataContext";
import getFromNestedObject from "common/utils/getFromNestedObject";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";

const blockId = "popup_form";

const TableAddForm = ({ fieldsAttrs, allDropdowns, funcsGetRowItem, funcSubmit, funcCloseForm }: any) => {
	/* 
    Keep in mind:
    parent datalevel will be lost in this form because we are passing it into the content-state of the popup window. 
  */
	const [data, setData] = useContext(FormDataContext);
	const dataLevel: any = useContext(FormDataLevelContext);
	const [oldValues, setOldValues]: any = useState({});
	const [defaultValues, setDefaultValues]: any = useState({});
	const [popupNotif, setPopupNotif] = useContext(PopupNotificationsContext);

	const handleSubmit = () => {
		const formData = getFromNestedObject(data, [blockId]);
		setData((c: any) => ({ ...deleteFromNestedObject(c, [blockId]) }));
		funcSubmit(formData);
		funcCloseForm();
	};

	const fillRowWithFetchedData = async (field_id: string, field_val: number) => {
		const result = await funcsGetRowItem[field_id](field_val);
		if (result.data) {
			setDefaultValues(() => result.data);
		} else {
			setPopupNotif((c: any) => [
				...c,
				{
					type: "error",
					msg: "Greška! Postojeće podatke nije moguće povući. Pokuštajte ponovo ili kontaktirajte vašeg administratora.",
					dateTime: Date.now(),
				},
			]);
		}
	};

	const fetchExistingDataAndFillForm = () => {
		// Check if a dropdown has changed value and has a
		// function to fetch existing data and fill the row with it.
		try {
			const formValues = getFromNestedObject(data, dataLevel);
			for (const field_id in funcsGetRowItem) {
				const formKeys: any = Object.keys(formValues);
				if (formKeys.includes(blockId) && Object.keys(formValues[blockId]).includes(field_id)) {
					const dropd_value = formValues[blockId][field_id];
					if (dropd_value !== "") {
						if (!Object.keys(oldValues).includes(field_id) || dropd_value !== oldValues[field_id]) {
							// if it's first value (first time set) OR new value (second and more time set)
							fillRowWithFetchedData(field_id, dropd_value);
						}
					}
					setOldValues((c: any) => ({ ...c, [field_id]: dropd_value }));
					break;
				}
			}
		} catch (error) {
			// catch our custom err 'data-level not found in object'
		}
	};

	useEffect(() => {
		fetchExistingDataAndFillForm();
	}, [data]);

	useEffect(() => {
		return () => setData((c: any) => ({ ...deleteFromNestedObject(c, [blockId]) }));
	}, []);

	if (fieldsAttrs && allDropdowns) {
		return (
			<Form>
				<FormDataLevelProvider blockId={blockId}>
					<InputGroup>
						{Object.entries(fieldsAttrs).map(([key, attrs]: any) => {
							if (!attrs.hide) {
								return (
									<InputFieldBlock
										key={key}
										size="md"
										title={`${attrs.required ? "*" : ""}${attrs.title}`}
										titlePos="l"
									>
										{inputField(key, attrs, allDropdowns, typeof defaultValues === "object" && defaultValues[key])}
									</InputFieldBlock>
								);
							}
						})}
						<ButtonGroup itemsPosition="r">
							{funcSubmit && (
								<Button size="md" onClick={() => handleSubmit()}>
									OK
								</Button>
							)}
							<button
								className="submitter"
								style={{ height: 0, width: 0, visibility: "hidden", display: "none" }}
								type="button"
							></button>
							<Button
								size="md"
								type="reset"
								color="danger"
								// onClick={() => {
								//   setData((c: any) => ({ ...deleteFromNestedObject(c, [blockId]) }));
								//   resetForm();
								// }}
							>
								Očisti polja
							</Button>
						</ButtonGroup>
					</InputGroup>
				</FormDataLevelProvider>
			</Form>
		);
	} else {
		return <LoaderSmallAndError errMsg={"errMsg"} funcTryAgain={null}></LoaderSmallAndError>;
	}
};

export default TableAddForm;
