const Info = () => {
  return (
    <svg viewBox="-121 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m195 60c0 33.136719-26.863281 60-60 60s-60-26.863281-60-60 26.863281-60 60-60 60 26.863281 60 60zm0 0" />
      <path d="m210 422v-272h-210v90h60v182h-60v90h270v-90zm0 0" />
    </svg>
  );
};

export default Info;
