import axios from "axios";
import ENDPOINTS from "appcompany/api/endpoints";
import tokenUtils from "applogin/utils/token";

export const uploadLogo = async (selectedFile: any) => {
	const formData = new FormData();
	formData.append("file", selectedFile);

	return await axios.post(ENDPOINTS.uploadLogo, formData, tokenUtils.addTokenToAuthHeaders());
};

export const getLogo = async () => {
	// IMPORTANT!!! responseType:'arraybuffer' must be set, to receive exact data
	return await axios.get(ENDPOINTS.getLogo, {
		...tokenUtils.addTokenToAuthHeaders(),
		responseType: "arraybuffer",
	});
};

export const getMyCompanies = async (obj: any) => {
	return await axios.post(ENDPOINTS.myCompanies, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateCompany = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateCompany, obj, tokenUtils.addTokenToAuthHeaders());
};
