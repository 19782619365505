import ItemsCreateTable from "appitems/components/ItemsCreateTable";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";

const ItemsCreate = () => {
  return (
    <>
      <Heading>Kreiraj stavke</Heading>

      <Section>
        <ItemsCreateTable />
      </Section>
    </>
  );
};

export default ItemsCreate;
