import Icons from "common/components/icons/Icons";
import { IIconTextBlockProps } from "common/interfaces/icon_text_block";

const IconTextBlock = (props: IIconTextBlockProps) => {
  let notifType = "";
  let notifIcon = "";

  if (!props.notifType) {
    notifType = "";
    notifIcon = "clear";
  } else if (props.notifType === "success") {
    notifType = "success";
    notifIcon = "notification";
  }

  return (
    <div className={`icon-text-block size-${props.size} ${props.classes}`}>
      {props.iconLeft && (
        <div className="zone-left">
          <div className={`icon ${props.iconColor} bg-${props.iconBg}`}>
            <Icons icon={props.iconLeft} />
          </div>
        </div>
      )}
      <div className="content">
        <p className={`block-title ${props.titleBold && "t-w-bold"}`}>{props.title}</p>
        {props.descr && <p className="block-description">{props.descr}</p>}
        {props.notif && (
          <p className={`block-notification ${notifType}`}>
            <span>
              <Icons icon={notifIcon} />
            </span>
            {props.notif}
          </p>
        )}
      </div>
      {props.iconRight && (
        <div className="zone-right">
          <div className={`icon ${props.iconColor} bg-${props.iconBg}`}>
            <Icons icon={props.iconRight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default IconTextBlock;
