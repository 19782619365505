import { Route } from "react-router-dom";
import InvoicesOut from "appdocsout/views_private/InvoicesOut";
import InvoiceOutCreate from "appdocsout/views_private/InvoiceOutCreate";
import InquiryOutCreate from "appdocsout/views_private/InquiryOutCreate";
import EstimateOutCreate from "appdocsout/views_private/EstimateOutCreate";
import QuoteOutCreate from "appdocsout/views_private/QuoteOutCreate";
import OrderFormOutCreate from "appdocsout/views_private/OrderFormOutCreate";
import AdvanceInvoiceOutCreate from "appdocsout/views_private/AdvanceInvoiceOutCreate";
import InquiriesOut from "appdocsout/views_private/InquiriesOut";
import EstimatesOut from "appdocsout/views_private/EstimatesOut";
import QuotesOut from "appdocsout/views_private/QuotesOut";
import OrderFormsOut from "appdocsout/views_private/OrderFormsOut";
import AdvanceInvoicesOut from "appdocsout/views_private/AdvanceInvoicesOut";
import DeliveryNotesOut from "appdocsout/views_private/DeliveryNotesOut";
import DeliveryNoteOutCreate from "appdocsout/views_private/DeliveryNoteOutCreate";

const DocsOutRoutes = () => {
  return (
    <>
      <Route path="/docs-out/inquiries" element={<InquiriesOut />} />
      <Route path="/docs-out/inquiry/create" element={<InquiryOutCreate />} />
      <Route path="/docs-out/estimates" element={<EstimatesOut />} />
      <Route path="/docs-out/estimate/create" element={<EstimateOutCreate />} />
      <Route path="/docs-out/quotes" element={<QuotesOut />} />
      <Route path="/docs-out/quote/create" element={<QuoteOutCreate />} />
      <Route path="/docs-out/order-forms" element={<OrderFormsOut />} />
      <Route path="/docs-out/order-form/create" element={<OrderFormOutCreate />} />
      <Route path="/docs-out/advance-invoices" element={<AdvanceInvoicesOut />} />
      <Route path="/docs-out/advance-invoice/create" element={<AdvanceInvoiceOutCreate />} />
      <Route path="/docs-out/invoices" element={<InvoicesOut />} />
      <Route path="/docs-out/invoice/create" element={<InvoiceOutCreate />} />
      <Route path="/docs-out/delivery-notes" element={<DeliveryNotesOut />} />
      <Route path="/docs-out/delivery-note/create" element={<DeliveryNoteOutCreate />} />
    </>
  );
};

export default DocsOutRoutes;
