import { getUsernames, updateUserPasswordByAdmin } from "applogin/api_admin/users";
import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import Subheading from "common/components/text/Subheading";

const UserPasswordsTable = () => {
	const blockId = "user_passwords";

	// const funcsGetDropdowns = {
	//   key: func,
	// };

	return (
		<>
			<Subheading>Passwordi korisnika:</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getUsernames({ fp: fetchParams })}
				funcUpdate={updateUserPasswordByAdmin}
				funcsGetDropdowns={null}
			></TableReadUpdateAuto>
		</>
	);
};

export default UserPasswordsTable;
