import axios from "axios";
import tokenUtils from "applogin/utils/token";
import ENDPOINTS_ADMIN from "appcompany/api_admin/endpoints_admin";

export const getMyCompanies = async (obj: any) => {
	return await axios.post(ENDPOINTS_ADMIN.myCompanies, obj, tokenUtils.addTokenToAuthHeaders());
};

export const createCompany = async (obj: any) => {
	return await axios.post(ENDPOINTS_ADMIN.createCompany, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateCompany = async () => {
	return await axios.get(ENDPOINTS_ADMIN.fieldsCreateCompany, tokenUtils.addTokenToAuthHeaders());
};

export const updateCompany = async (obj: any) => {
	return await axios.patch(ENDPOINTS_ADMIN.updateCompany, obj, tokenUtils.addTokenToAuthHeaders());
};
