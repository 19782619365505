import { createContext } from "react";

/* 
- Data is an object containing the data to be sent.
- Use it in combination with FormDataLevelContext/Provider.
- Data Level means "the level of nesting in an object".
Example (3 levels of nesting: 1, 1.1, 1.1.1):
const data = {
    "attr-1":{
        "attr-1.1":{
            "attr-1.1.1": 0,
            "attr-1.1.2": 0,
        },
        "attr-2.1":{
            "attr-2.1.1": 0,
            "attr-2.1.2": 0,
        }
    }
}
 */

/**
 * HOW TO ADD VALUES:
 * 
 * we need createNestedObject() function !!!
 * 
 * const [dataContx, setDataContx] = useContext(FormDataContext);
 * setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, blockId], selectedDatabaseIds) }));
 * 
 */

export const FormDataContext = createContext<any>({});

