import { createContext, useState } from "react";
import { USER_CONSTS } from "../constants/userConsts";
import { IUserInfo } from "../interfaces/users";

export const UserInfoContext = createContext<any>(null);

export const UserInfoProvider = ({ children }: any) => {
  const [userInfoState, setUserInfoState] = useState<IUserInfo>(USER_CONSTS.initialUserInfo);

  return <UserInfoContext.Provider value={[userInfoState, setUserInfoState]}>{children}</UserInfoContext.Provider>;
};
