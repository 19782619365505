import { getFieldsRegisterUser, registerUser } from "applogin/api_admin/register";
import Subheading from "common/components/text/Subheading";
import FormAuto from "common/components/forms/FormAuto";

const UserRegisterForm = () => {
  const blockId = "user_register";

  // const funcsGetDropdowns = {
  //   key: func,
  // };

  return (
    <>
      <Subheading>Kreiraj korisnika:</Subheading>
      <FormAuto
        blockId={blockId}
        funcGetFieldsAttrs={getFieldsRegisterUser}
        funcSubmit={registerUser}
        funcsGetDropdowns={null}
      ></FormAuto>
    </>
  );
};

export default UserRegisterForm;
