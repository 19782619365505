import Button from "common/components/btns/Button";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import FormDataLevelProvider from "common/contexts/FormDataLevelProvider";
import { ICreateRowsProps } from "common/interfaces/table";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import getFromNestedObject from "common/utils/getFromNestedObject";
import createNestedObject from "common/utils/createNestedObject";
import { useContext, useEffect, useState } from "react";
import Row from "./Row";
import RowPlaceholder from "./RowPlaceholder";

const isLastRowEmpty = (tableRef: any) => {
  if (tableRef) {
    const rows = tableRef.current.querySelectorAll("tr.data-row");
    if (rows.length > 0) {
      const lastRow = rows[rows.length - 1];
      const lastRowInputFields: any = lastRow.querySelectorAll(`input`);
      if (lastRowInputFields) {
        for (const field of lastRowInputFields) {
          if (field.type != "checkbox" && field.value != "") {
            return false;
          }
        }
      }
    }
  }
  return true;
};

const Rows = ({ tableRef, fieldsAttrs, dropdowns, funcsGetRowItem }: ICreateRowsProps) => {
  const dataLevelCxt: any = useContext(FormDataLevelContext); // we must use this name because dataLevel is used in some of our functions
  const [data, setData] = useContext(FormDataContext);
  const [rowsKeys, setRowsKeys]: any = useState([{ key: Date.now() }]); // format is {key: Date.now(), sourceRowValues: {a: 1, b:2}}

  function addRowIfInputInLastRow() {
    if (!isLastRowEmpty(tableRef)) {
      setRowsKeys((c: any) => [...c, { key: Date.now() }]);
    }
  }

  function deleteRow(dataLevel: string[]) {
    const rowKey = dataLevel[dataLevel.length - 1];
    setRowsKeys((c: any) => c.filter((k: any) => k["key"] !== rowKey));
    setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
  }

  const duplicateRow = (dataLevel: string[]) => {
    const sourceRowValues = getFromNestedObject(data, dataLevel);
    setRowsKeys((c: any) => {
      let newArr = [...c];
      newArr.splice(newArr.length - 1, 0, { key: Date.now(), sourceRowValues: sourceRowValues });
      return newArr;
    });
  };

  const triggerDuplicateRow = (dataLevel: string[]) => {
    // we must do it like that to hold the data context in this class and not to pass it
    duplicateRow(dataLevel);
  };

  const populateNewRow = (passedData: any) => {
    // const key = Date.now();
    // const sourceRowValues = createNestedObject(data, [...dataLevelCxt, key], passedData);
    setRowsKeys((c: any) => {
      let newArr = [...c];
      newArr.splice(newArr.length - 1, 0, { key: Date.now(), sourceRowValues: passedData });
      return newArr;
    });
  };

  const triggerPopulateNewRow = (passedData: any) => {
    // we must do it like that to hold the data context in this class and not to pass it
    populateNewRow(passedData);
  };

  useEffect(() => {
    addRowIfInputInLastRow();
  }, [data]);

  return (
    <>
      {rowsKeys.map(({ key, sourceRowValues }: any, index: number) => {
        const isLastRow = rowsKeys.length - 1 === index;
        return (
          <FormDataLevelProvider blockId={key} key={key}>
            <Row
              fieldsAttrs={fieldsAttrs}
              dropdowns={dropdowns}
              funcsGetRowItem={funcsGetRowItem}
              sourceRowValues={sourceRowValues}
              classes={`data-row ${key} ${isLastRow && "last-row"}`}
              isLastRow={isLastRow}
              deleteRow={deleteRow}
              duplicateRow={triggerDuplicateRow}
            />
          </FormDataLevelProvider>
        );
      })}
      {[1, 2].map((nr: number) => (
        <RowPlaceholder
          key={nr}
          classes={`placeholder-row row-${nr}`}
          fieldsAttrs={fieldsAttrs}
          allDropdowns={dropdowns}
          funcsGetRowItem={funcsGetRowItem}
          funcSubmit={triggerPopulateNewRow}
        />
      ))}
    </>
  );
};

export default Rows;
