import { getUsers } from "applogin/api/users";
import { updateUserInfoByAdmin } from "applogin/api_admin/users";
import { getUserStatusesDropdown } from "applogin/api/userStatuses";
import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import Subheading from "common/components/text/Subheading";

const UsersTable = () => {
	const blockId = "users";

	const funcsGetDropdowns = {
		status_id: getUserStatusesDropdown,
	};

	return (
		<>
			<Subheading>Korisnici:</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getUsers({ fp: fetchParams })}
				funcUpdate={updateUserInfoByAdmin}
				funcsGetDropdowns={funcsGetDropdowns}
			></TableReadUpdateAuto>
		</>
	);
};

export default UsersTable;
