import axios from "axios";
import ENDPOINTS from "appitems/api/endpoints";
import tokenUtils from "applogin/utils/token";

export const getItems = async (obj: any) => {
	return await axios.post(ENDPOINTS.items, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getItemById = async (itemId: number) => {
	return await axios.get(ENDPOINTS.item + "/" + itemId, tokenUtils.addTokenToAuthHeaders());
};

export const getItemsDropdown = async () => {
	return await axios.get(ENDPOINTS.itemsDropdown, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateItem = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateItem, tokenUtils.addTokenToAuthHeaders());
};

export const createItem = async (obj: any) => {
	return await axios.post(ENDPOINTS.createItem, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateItem = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateItem, obj, tokenUtils.addTokenToAuthHeaders());
};
