import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import { getMyCompanies, updateCompany } from "appcompany/api/companies";
import Subheading from "common/components/text/Subheading";
// import { getLegalEntityTypesDropdown } from "appassociates/api/associates";
import {getCountriesDropdown} from "appunits/api/countries";

const CompaniesTable = () => {
  const blockId = "companies";

  const funcsGetDropdowns = {
    country_code_iso: getCountriesDropdown,
    // legal_entity_type_id: getLegalEntityTypesDropdown,
  };

  return (
    <>
      <Subheading>Podaci o firmi:</Subheading>
      <TableReadUpdateAuto
        blockId={blockId}
				funcGetData={(fetchParams: object) => getMyCompanies({ fp: fetchParams })}
        funcsGetDropdowns={funcsGetDropdowns}
        funcUpdate={updateCompany}
      ></TableReadUpdateAuto>
    </>
  );
};

export default CompaniesTable;
