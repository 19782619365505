import LegalEntityAddForm from "appassociates/components/LegalEntityAddForm";
import NaturalPersonAddForm from "appassociates/components/NaturalPersonAddForm";
import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";
import { useState } from "react";

const AddAssociate = () => {
  const [isLegalEntity, setIsLegalEntity] = useState(true);

  return (
    <>
      <Heading>Dodaj lice (pravno/fizičko)</Heading>
      
      <Section>
        <ButtonGroup itemsPosition="c">
          <Button size="md" classes={isLegalEntity && "active"} onClick={() => setIsLegalEntity(true)}>
            Pravno lice
          </Button>
          <Button size="md" classes={!isLegalEntity && "active"} onClick={() => setIsLegalEntity(false)}>
            Fizičko lice
          </Button>
        </ButtonGroup>
        {isLegalEntity ? <LegalEntityAddForm /> : <NaturalPersonAddForm />}
      </Section>
    </>
  );
};

export default AddAssociate;
