const Collapse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 123.959 123.958"
    >
      <g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,123.95830726623538,123.95900344848631)">
        <g xmlns="http://www.w3.org/2000/svg">
          <path d="M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56   C125.979,34.417,123.279,28.017,117.979,28.017z" />
        </g>
      </g>
    </svg>
  );
};

export default Collapse;
