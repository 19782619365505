const LoaderFullPage = () => {
  return (
    <div className="full-page-loader-container">
      <div className="loader">
        <div className="loader-container loader-small">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <span>Učitavanje ...</span>
      </div>
    </div>
  );
};

export default LoaderFullPage;
