import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { IFormDataLevelProviderProps } from "common/interfaces/context_form_data";
import { useContext } from "react";

const FormDataLevelProvider = ({ children, blockId }: IFormDataLevelProviderProps) => {
  const address = useContext(FormDataLevelContext);

  return (
    <>
      <FormDataLevelContext.Provider value={[...address, blockId]}>{children}</FormDataLevelContext.Provider>
    </>
  );
};

export default FormDataLevelProvider;
