import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import Subheading from "common/components/text/Subheading";
import { getLegalEntities, updateLegalEntity } from "appassociates/api/associates";
import { getCountriesDropdown } from "appunits/api/countries";

const LegalEntitiesTable = () => {
	const blockId = "legal_entities";

	const funcsGetDropdowns = {
		country_code_iso: getCountriesDropdown,
		// legal_entity_type_id: getLegalEntityTypesDropdown,
	};

	return (
		<>
			<Subheading>Pravna lica:</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getLegalEntities({ fp: fetchParams })}
				funcsGetDropdowns={funcsGetDropdowns}
				funcUpdate={updateLegalEntity}
			></TableReadUpdateAuto>
		</>
	);
};

export default LegalEntitiesTable;
