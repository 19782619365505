import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import Subheading from "common/components/text/Subheading";
import { getNaturalPersons, updateNaturalPerson } from "appassociates/api/associates";
import { getCountriesDropdown } from "appunits/api/countries";

const NaturalPersonsTable = () => {
	const blockId = "natural_persons";

	const funcsGetDropdowns = {
		country_code_iso: getCountriesDropdown,
	};

	return (
		<>
			<Subheading>Fizička lica:</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getNaturalPersons({ fp: fetchParams })}
				funcsGetDropdowns={funcsGetDropdowns}
				funcUpdate={updateNaturalPerson}
			></TableReadUpdateAuto>
		</>
	);
};

export default NaturalPersonsTable;
