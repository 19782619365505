import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Subheading from "common/components/text/Subheading";
import { useEffect, useState } from "react";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import CurrentImage from "./base_upload_file/CurrentImage";
import { IUploadFileProps } from "common/interfaces/input_field";

const UploadFile = ({ title, funcSubmit, funcGetCurrentImage, currentImageSize }: IUploadFileProps) => {
	const [isActive, setIsActive] = useState(false);
	const [selectedFile, setSelectedFile]: any = useState("");
	const [isChildEntered, setIsChildEntered] = useState(false);
	const [resultFromSubmit, sub] = useSubmitterAsync();
	const [currImgKey, setCurrImgKey] = useState(Date.now()); // use this key to have control when to refresh the image

	function dropHandler(e: any) {
		e.preventDefault(); // Prevent file from being opened
		setIsActive(false);
		if (e.dataTransfer.items) {
			if (e.dataTransfer.items.length > 0) {
				setSelectedFile(e.dataTransfer.files[0]);
			}
		} else {
			if (e.dataTransfer.files.length > 0) {
				setSelectedFile(e.dataTransfer.files[0]);
			}
		}
	}

	const dragEnterHandler = (e: any) => {
		// NOTE: always is first dragEnter of new element called and then dragLeave of the old element
		e.preventDefault(); // Prevent file from being opened
		setIsActive(true);
		const childEntered = e.target.id != "main-parent";
		setIsChildEntered((c: any) => childEntered);
	};

	const dragLeaveHandler = (e: any) => {
		// NOTE: always is dragEnter of new element first called and then dragLeave of the old element
		e.preventDefault(); // Prevent file from being opened
		const leftToOutside = e.target.id == "main-parent" && !isChildEntered;
		if (leftToOutside) {
			setIsActive(false);
		}
	};

	const dragOverHandler = (e: any) => {
		e.preventDefault(); // Prevent file from being opened
	};

	const refreshForm = () => {
		setSelectedFile("");
		setCurrImgKey(() => Date.now());
	};

	const submitData = async () => {
		sub(() => funcSubmit(selectedFile));
	};

	useEffect(() => {
		// after data submitted
		refreshForm();
	}, [resultFromSubmit]);

	useEffect(() => {
		refreshForm();
	}, []);

	return (
		<>
			<Subheading>{title}</Subheading>
			<div className="upload-block">
				<div
					id="main-parent"
					className={`drag-drop-zone ${isActive && "active"} m-b-060`}
					onDragEnter={dragEnterHandler}
					onDragLeave={dragLeaveHandler}
					onDragOver={dragOverHandler}
					onDrop={dropHandler}
				>
					{funcGetCurrentImage && (
						<div className="current-file-container">
							<p>Trenutni file:</p>
							<CurrentImage key={currImgKey} size={currentImageSize} funcGetCurrentImage={funcGetCurrentImage} />
						</div>
					)}
					<div>
						<span>
							{!selectedFile && (
								<>
									<span className="description"><strong>Prenesite novu datoteku </strong> u ovu zonu ili kliknite</span>
									<label htmlFor="file" className="btn">
										Odaberi datoteku
									</label>
									<input
										id="file"
										type="file"
										style={{ display: "none" }}
										onChange={(e: any) => setSelectedFile(e.target.files[0])}
									/>
								</>
							)}
							{selectedFile && (
								<p className="selected-file-name">
									Odabrana datoteka: <span>{selectedFile.name}</span>
								</p>
							)}
						</span>
					</div>
				</div>
				<ButtonGroup itemsPosition="c">
					<Button size="md" type="submit" onClick={() => submitData()} disabled={!selectedFile}>
						OK
					</Button>
					<Button size="md" type="button" color="danger" onClick={() => setSelectedFile("")} disabled={!selectedFile}>
						Odustani
					</Button>
				</ButtonGroup>
			</div>
		</>
	);
};

export default UploadFile;
