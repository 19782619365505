import axios from "axios";
import ENDPOINTS from "appassociates/api/endpoints";
import tokenUtils from "applogin/utils/token";

// export const getLegalEntityTypes = async () => {
// 	return await axios.get(ENDPOINTS.legalEntityTypes, tokenUtils.addTokenToAuthHeaders());
// };

// export const getLegalEntityTypesDropdown = async () => {
// 	return await axios.get(ENDPOINTS.legalEntityTypesDropdown, tokenUtils.addTokenToAuthHeaders());
// };

export const getLegalEntities = async (obj: any) => {
	return await axios.post(ENDPOINTS.legalEntities, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getLegalEntitiesDropdown = async () => {
	return await axios.get(ENDPOINTS.legalEntitiesDropdown, tokenUtils.addTokenToAuthHeaders());
};

export const getNaturalPersons = async (obj: any) => {
	return await axios.post(ENDPOINTS.naturalPersons, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getNaturalPersonsDropdown = async () => {
	return await axios.get(ENDPOINTS.naturalPersonsDropdown, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateLegalEntity = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateLegalEntity, tokenUtils.addTokenToAuthHeaders());
};

export const createLegalEntity = async (obj: any) => {
	return await axios.post(ENDPOINTS.createLegalEntity, obj, tokenUtils.addTokenToAuthHeaders());
};

export const getFieldsCreateNaturalPerson = async () => {
	return await axios.get(ENDPOINTS.fieldsCreateNaturalPerson, tokenUtils.addTokenToAuthHeaders());
};

export const createNaturalPerson = async (obj: any) => {
	return await axios.post(ENDPOINTS.createNaturalPerson, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateLegalEntity = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateLegalEntity, obj, tokenUtils.addTokenToAuthHeaders());
};

export const updateNaturalPerson = async (obj: any) => {
	return await axios.patch(ENDPOINTS.updateNaturalPerson, obj, tokenUtils.addTokenToAuthHeaders());
};
