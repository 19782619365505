import { baseURL, baseURLUpload } from "common/config/settings";

const prefix = "/my-companies";

const ENDPOINTS = {
	myCompanies: `${baseURL}${prefix}/all`,
	updateCompany: `${baseURL}${prefix}/update`,
	uploadLogo: `${baseURLUpload}${prefix}/upload/logo`,
	getLogo: `${baseURL}${prefix}/company-logo`,
};

export default ENDPOINTS;
