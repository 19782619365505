import Icons from "common/components/icons/Icons";
import { IInputFieldProps } from "common/interfaces/input_field";
import { useEffect, useRef, useState } from "react";

const getInputFieldProps = (props: any) => {
  const {
    type,
    name,
    value,
    defaultValue,
    placeholder,
    form,
    autoComplete,
    disabled,
    required,
    title,
    step,
    min,
    max,
  } = props;
  let o: any = {}; // props object

  type && (o.type = type);
  name && (o.name = name);
  // value && (o.value = value);
  defaultValue && (o.defaultValue = defaultValue);
  placeholder && (o.placeholder = placeholder);
  form && (o.form = form);
  autoComplete && (o.autoComplete = autoComplete);
  disabled && (o.disabled = disabled);
  required && (o.required = required);
  title && (o.title = title);
  step && (o.step = step);
  min && (o.min = min);
  max && (o.max = max);
  return o;
};

const getLayout = (props: any) => {
  switch (props.layout) {
    case "icon-only":
      return "input-type-icon";
    case "icon-left":
      return "input-type-icon-left";
    case "icon-right":
      return "input-type-icon-right";
    case "text-left":
      return "input-type-text-left";
    case "text-right":
      return "input-type-text-right";
    default: // empty is normal input field
      return "";
  }
};

const InputFieldClassic = (props: IInputFieldProps) => {
  const [val, setVal]: any = useState();
  const inputRef = useRef<HTMLInputElement>(null);
  const clearDisabled = props.disableClearBtn || props.disabled ? "clear-disabled" : "";

  return (
    <div
      id={props.id ? props.id : ""}
      className={`input-btn-container ${getLayout(props)} size-${props.size} no-arrows-${props.noArrows} ${
        props.classes
      }`}
    >
      <div className="input-container">
        <div className="input-grid-container">
          {props.icon && (
            <div className="icon-container">
              <Icons icon={props.icon} />
            </div>
          )}
          {props.helperText && (
            <div className="helper-text-container">
              <span className="helper-text">{props.helperText}</span>
            </div>
          )}
          <input size={1} ref={inputRef} value={props.value} onChange={props.onChange} {...getInputFieldProps(props)} />
          <button
            tabIndex={-1}
            type="button"
            className={`btn-clear align-v-center ${clearDisabled}`}
            onClick={(e: any) => {
              setVal("");
              inputRef.current!.focus();
            }}
          >
            <Icons icon="clear" />
          </button>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default InputFieldClassic;
