import { getMyCompanies, updateCompany } from "appcompany/api_admin/companies";
// import { getLegalEntityTypesDropdown } from "appassociates/api/associates";
import TableReadUpdateAuto from "common/components/tables/TableReadUpdateAuto";
import Subheading from "common/components/text/Subheading";
import {getCountriesDropdown} from "appunits/api/countries";

const CompaniesTable = () => {
	const blockId = "companies";

	const funcsGetDropdowns = {
		country_code_iso: getCountriesDropdown,
		// legal_entity_type_id: getLegalEntityTypesDropdown,
	};

	return (
		<>
			<Subheading>Naše firme:</Subheading>
			<TableReadUpdateAuto
				blockId={blockId}
				funcGetData={(fetchParams: object) => getMyCompanies({ fp: fetchParams })}
				funcsGetDropdowns={funcsGetDropdowns}
				funcUpdate={updateCompany}
			></TableReadUpdateAuto>
		</>
	);
};

export default CompaniesTable;
