import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Dropdown from "common/components/inputs/Dropdown";
import InputField from "common/components/inputs/InputField";
import InputFieldBlock from "common/components/inputs/InputFieldBlock";
import InputGroup from "common/components/inputs/InputGroup";
import { formatDate } from "common/utils/formatDate";
import Form from "./base/Form";
import FormDataLevelProvider from "../../contexts/FormDataLevelProvider";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import { useContext, useEffect, useState } from "react";
import { FormDataContext } from "common/contexts/FormDataContext";
import { IAutoFieldsFormProps2 } from "common/interfaces/form";
import Checkbox from "../inputs/Checkbox";
import useSubmitterAsync from "common/hooks/useSubmitterAsync";
import getMsgFromHttpErrors from "common/utils/getMsgFromHttpErrors";
import LoaderSmallAndError from "../loaders/LoaderSmallAndError";
import { useNavigate } from "react-router-dom";

const getInputFieldProps = (key: string, attrs: any, defaultState?: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object
	o.name = key;
	o.size = "md";
	o.disableClearBtn = true;
	o.autoComplete = false;
	defaultState && (o.defaultState = defaultState);
	// defaultVal && (o.value = defaultVal);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const getCheckboxProps = (key: string, attrs: any, defaultState: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object

	o.name = key;
	o.size = "md";
	o.includeDefaultValInData = false;
	defaultState && (o.defaultState = defaultState);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const inputField = (key: any, attrs: any, dropdowns: any, defaultState?: any) => {
	let fieldProps: any = {};
	if (attrs.type === "boolean") {
		fieldProps = getCheckboxProps(key, attrs, defaultState);
	} else {
		fieldProps = getInputFieldProps(key, attrs, defaultState);
	}

	if (attrs.dropdown && dropdowns) {
		return <Dropdown {...fieldProps} items={dropdowns[key]} />;
	} else if (attrs.type === "boolean") {
		return <Checkbox {...fieldProps} />;
		// return <input {...fieldProps} type="checkbox" />;
	} else if (attrs.format == "date") {
		return <InputField {...fieldProps} type="date" min={formatDate(Date.now(), "Y4+F-+M2+F-+D2")} />;
	} else if (attrs.type === "number" || attrs.type === "integer") {
		return <InputField {...fieldProps} type="number" noArrows={true} />;
	} else if (attrs.type === "text") {
		return <InputField {...fieldProps} isTextarea={true} />;
	} else {
		return <InputField {...fieldProps} />;
	}
};

const customError = (msg: string) => {
	return {
		response: {
			status: 123,
			data: {
				detail: msg,
			},
		},
	};
};

const FormAuto = ({ children, blockId, funcGetFieldsAttrs, funcSubmit, funcsGetDropdowns }: IAutoFieldsFormProps2) => {
	const [data, setData] = useContext(FormDataContext);
	const [fieldsAttrs, setFieldsAttrs]: any = useState(null);
	const [allDropdowns, setAllDropdowns]: any = useState(null);
	const [errMsg, setErrMsg] = useState("");
	const [resultFromSubmit, sub] = useSubmitterAsync();
	const [resetterKey, setResetterKey] = useState(0); // this is used to enable resetForm function

	const getFieldsAttrs: any = async () => {
		setFieldsAttrs((c: any) => null); // always empty data to run the loader
		try {
			if (funcGetFieldsAttrs) {
				const result = await funcGetFieldsAttrs();
				if (result.data) {
					setFieldsAttrs(result.data["fields"]);
				} else {
					throw customError("Greška! Polja su nedostupna. Pokuštajte ponovo ili kontaktirajte vašeg administratora.");
				}
			}
		} catch (error: any) {
			console.log("[KERR]", error);
			setErrMsg(getMsgFromHttpErrors(error));
		}
	};

	const getDropdowns: any = async () => {
		setAllDropdowns((c: any) => null); // always empty data to run the loader
		if (funcsGetDropdowns && Object.keys(funcsGetDropdowns).length > 0) {
			try {
				let results: any = {};
				Object.entries(funcsGetDropdowns).forEach(async ([key, func]: any) => {
					try {
						// we need here also a try catch as the outer one cannot catch 'uncaught promise error'
						results[key] = await func();
						if (results[key]) {
							setAllDropdowns((c: any) => ({ ...c, [key]: results[key].data }));
						}
					} catch (error: any) {
						console.log("[KERR]", error);
						setErrMsg(getMsgFromHttpErrors(error));
					}
				});
			} catch (error: any) {
				console.log("[KERR]", error);
				setErrMsg(getMsgFromHttpErrors(error));
			}
		}
	};

	const refreshForm = () => {
		setErrMsg((c: any) => "");
		getFieldsAttrs();
		getDropdowns();
	};

	const resetForm = () => {
		// WHY DO WE NEED THIS FUNCTION:
		// every render key must be new, if not, after reset form, old values will come back on next render
		setResetterKey((c: number) => c + 1);
	};

	const submitData = async () => {
		sub(() => funcSubmit(data[blockId]));
	};

	useEffect(() => {
		// after data submitted
		if (resultFromSubmit) {
			const dataLevel = [blockId];
			setData((c: any) => ({ ...deleteFromNestedObject(c, dataLevel) }));
			refreshForm();
		}
	}, [resultFromSubmit]);

	useEffect(() => {
		if (!fieldsAttrs) {
			refreshForm();
		}
	}, [fieldsAttrs]);

	let dropdownsReady;
	if (funcsGetDropdowns) {
		dropdownsReady = allDropdowns;
	} else {
		// if dropdowns func is not provided (not needed) then set true
		dropdownsReady = true;
	}

	if (fieldsAttrs && dropdownsReady) {
		return (
			<Form>
				<FormDataLevelProvider blockId={blockId}>
					<InputGroup key={resetterKey}>
						{Object.entries(fieldsAttrs).map(([key, attrs]: any) => {
							if (!attrs.hide) {
								return (
									<InputFieldBlock
										key={key}
										size="md"
										title={`${attrs.required ? "*" : ""}${attrs.title}`}
										titlePos="l"
									>
										{inputField(key, attrs, allDropdowns)}
									</InputFieldBlock>
								);
							}
						})}
						<ButtonGroup itemsPosition="r">
							{funcSubmit && (
								<Button size="md" onClick={() => submitData()}>
									OK
								</Button>
							)}
							<button
								className="submitter"
								style={{ height: 0, width: 0, visibility: "hidden", display: "none" }}
								type="button"
							></button>
							<Button
								size="md"
								type="reset"
								color="danger"
								onClick={() => {
									setData((c: any) => ({ ...deleteFromNestedObject(c, [blockId]) }));
									resetForm();
								}}
							>
								Očisti polja
							</Button>
							{children}
						</ButtonGroup>
					</InputGroup>
				</FormDataLevelProvider>
			</Form>
		);
	} else {
		return <LoaderSmallAndError errMsg={errMsg} funcTryAgain={refreshForm}></LoaderSmallAndError>;
	}
};

export default FormAuto;

export { inputField };
