import { UserInfoContext } from "applogin/contexts/UserInfoProvider";
import AdminMenuLinks from "common/components/menus/AdminMenuLinks";
import UserMenuLinks from "common/components/menus/UserMenuLinks";
import { useContext } from "react";

const MainMenu = () => {
  const [userInfoState, setUserInfoState] = useContext(UserInfoContext);

  const closeMenu = () => {
    const closeBtn: any = document.querySelector(".main-menu #close-popover");
    closeBtn.click();
  };

  return (
    <nav className="main-menu">
      <div className="grid-container">
        {userInfoState.isAdmin && userInfoState.isAuthenticated && <AdminMenuLinks onClick={closeMenu} />}
        {!userInfoState.isAdmin && userInfoState.isAuthenticated && <UserMenuLinks onClick={closeMenu} />}
      </div>
    </nav>
  );
};

export default MainMenu;
