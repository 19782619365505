import { getUnitsOfMeasurementDropdown } from "appunits/api/units_of_measurement";
import { getCurrenciesDropdown } from "appunits/api/currencies";
import Subheading from "common/components/text/Subheading";
import TableCreateAuto from "common/components/tables/TableCreateAuto";
import { createItem, getFieldsCreateItem } from "appitems/api/items";

const ItemsCreateTable = () => {
  const blockId = "doc_items_unregistered";

  const funcsGetDropdowns = {
    unit_of_measurement_id: getUnitsOfMeasurementDropdown,
    currency_id: getCurrenciesDropdown,
  };

  return (
    <>
      <Subheading>Nove stavke:</Subheading>
      <TableCreateAuto
        blockId={blockId}
        funcGetFieldsAttrs={getFieldsCreateItem}
        funcsGetDropdowns={funcsGetDropdowns}
        funcSubmit={createItem}
      />
    </>
  );
};

export default ItemsCreateTable;
