import Button from "common/components/btns/Button";
import { PopoverWindowContext } from "common/contexts/PopoverWindowProvider";
import { useContext, useEffect, useRef } from "react";

const PopoverWindow = () => {
  const [content, setContent] = useContext(PopoverWindowContext);
  const ref = useRef(null);
  const ref_2 = useRef(null);

  const handleShowHide = () => {
    setContent((c: any) => null);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target) && !ref_2.current.contains(event.target)) {
        handleShowHide();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleShowHide]);

  if (content === null) {
    return <></>;
  } else {
    return (
      <div className="popover-window">
        <div className="btn-zone">
          <Button
            ref={ref_2}
            size="lg"
            layout="icon-only"
            icon="close"
            color="transparent"
            classes="btn-close-window"
            onClick={() => setContent((c: any) => null)}
          ></Button>
        </div>
        <div ref={ref} className="content">
          {content}
        </div>
      </div>
    );
  }
};

export default PopoverWindow;
