// import baseURLUtils from "applogin/utils/_baseURL";

// // NOTE:
// // attributes must be arrow functions to be able to envoke getLocalBaseURL(), if not
// // then Null would always be returned

// const ENDPOINTS = {
//   unitsOfMeasurement: () => `${baseURLUtils.getLocalBaseURL()}/units-of-measurement/all`,
//   unitsOfMeasurementDropdown: () => `${baseURLUtils.getLocalBaseURL()}/units-of-measurement/dropdown`,
//   currencies: () => `${baseURLUtils.getLocalBaseURL()}/currencies/all`,
//   currenciesDropdown: () => `${baseURLUtils.getLocalBaseURL()}/currencies/dropdown`,
//   taxes: () => `${baseURLUtils.getLocalBaseURL()}/taxes/all`,
//   taxesDropdown: () => `${baseURLUtils.getLocalBaseURL()}/taxes/dropdown`,
// };

import { baseURL } from "common/config/settings";

const ENDPOINTS = {
	unitsOfMeasurement: `${baseURL}/units-of-measurement/all`,
	unitsOfMeasurementDropdown: `${baseURL}/uom/dropdown`,
	currencies: `${baseURL}/currencies/all`,
	currenciesDropdown: `${baseURL}/currencies/dropdown`,
	countriesDropdown: `${baseURL}/countries/dropdown`,
	taxes: `${baseURL}/taxes/all`,
	taxesDropdown: `${baseURL}/taxes/dropdown`,
};

export default ENDPOINTS;
