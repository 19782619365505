import { ITablePaginationProps } from "common/interfaces/table";
import { useEffect } from "react";
import Button from "../../btns/Button";
import Dropdown from "../../inputs/Dropdown";
import DropdownClassic from "../../inputs/DropdownClassic";
import InputFieldClassic from "../../inputs/InputFieldClassic";

const Pagination = ({
	canPreviousPage,
	canNextPage,
	pageIndex,
	pageCount,
	pageSize,
	pageOptions,
	setterPreviousPage,
	setterNextPage,
	setterGotoPage,
	setterSetPageSize,
}: ITablePaginationProps) => {
	const btnProps = { size: "sm", color: "transparent", colorTextInvert: true };
	//   const btnProps = { size: "sm" };
	const pageSizeOptions = [
		{ value: 10, text: "10 redova po stranici" },
		{ value: 20, text: "20 redova po stranici" },
		{ value: 30, text: "30 redova po stranici" },
		{ value: 50, text: "50 redova po stranici" },
		{ value: 100, text: "100 redova po stranici" },
		{ value: 500, text: "500 redova po stranici" },
		{ value: 1000, text: "1000 redova po stranici" },
	];

	return (
		<div className="pagination">
			<div className="options-container">
				<Button {...btnProps} onClick={() => setterGotoPage(1)} disabled={pageIndex < 2} classes="extremes">
					prva
				</Button>
				<Button {...btnProps} onClick={() => setterPreviousPage()} disabled={pageIndex < 2} classes="closests">
					{" < "}
				</Button>
				<InputFieldClassic
					// layout="text-right" // this is for total-pages
					// helperText={" / " + pageOptions.length} // this is for total-pages
					size="sm"
					disabled={!canPreviousPage}
					disableClearBtn={true}
					type="number"
					noArrows={true}
					value={pageIndex}
					onChange={(e: any) => {
						const val = e.target.value;
						setterGotoPage(val && val > 1 ? val : 1);
					}}
					classes={`page-input ${!canPreviousPage ? "disabled" : ""} t-center`}
				/>
				<Button {...btnProps} onClick={() => setterNextPage()} disabled={false} classes="closests">
					{/* This button is going to be always enabled, because for now we do not have total number of pages */}
					{" > "}
				</Button>
				{/* <Button {...btnProps} onClick={() => setterGotoPage(pageCount - 1)} disabled={!canNextPage} classes="extremes">
					posljednja
				</Button> */}
			</div>
			<div>
				<DropdownClassic
					// disabled={true} // disable it for MVP
					size="sm"
					items={pageSizeOptions}
					firstEmpty={false}
					defaultValue={pageSize}
					onChange={(e: any) => {
						setterSetPageSize(Number(e.target.value));
					}}
				/>
			</div>
		</div>
	);
};

export default Pagination;
