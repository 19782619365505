import Heading from "common/components/text/Heading";
import PermissionsTable from "appadmin/components/PermissionsTable";
import PermissionAddForm from "appadmin/components/PermissionAddForm";
import Section from "common/components/layout_zones/Section";

const AdminPermissions = () => {
  return (
    <>
      <Heading>Ovlaštenja</Heading>

      <Section>
        <PermissionAddForm />
      </Section>

      <Section>
        <PermissionsTable />
      </Section>
    </>
  );
};

export default AdminPermissions;
