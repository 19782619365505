import Calendar from "./Calendar";
import City from "./City";
import Clear from "./Clear";
import Close from "./Close";
import Collapse from "./Collapse";
import Copy from "./Copy";
import Delete from "./Delete";
import Download from "./Download";
import Edit from "./Edit";
import Email from "./Email";
import Error from "./Error";
import Expand from "./Expand";
import Folder from "./Folder";
import Hide from "./Hide";
import Home from "./Home";
import Info from "./Info";
import Key from "./Key";
import Left from "./Left";
import Menu from "./Menu";
import Message from "./Message";
import Notification from "./Notification";
import NotificationActive from "./NotificationActive";
import Ok from "./Ok";
import Person from "./Person";
import Profile from "./Profile";
import Question from "./Question";
import Refresh from "./Refresh";
import Right from "./Right";
import Save from "./Save";
import Search from "./Search";
import Send from "./Send";
import Settings from "./Settings";
import Show from "./Show";
import Success from "./Success";
import Trash from "./Trash";
import Warning from "./Warning";
import WarningSign from "./WarningSign";

const IconsSolid = (props: any) => {
  switch (props.icon) {
    case "calendar":
      return <Calendar />;
    case "city":
      return <City />;
    case "clear":
      return <Clear />;
    case "close":
      return <Close />;
    case "collapse":
      return <Collapse />;
    case "copy":
      return <Copy />;
    case "delete":
      return <Delete />;
    case "download":
      return <Download />;
      case "edit":
        return <Edit />;
    case "email":
      return <Email />;
      case "error":
        return <Error />;
    case "expand":
      return <Expand />;
    case "folder":
      return <Folder />;
    case "hide":
      return <Hide />;
    case "home":
      return <Home />;
    case "info":
      return <Info />;
    case "key":
      return <Key />;
    case "left":
      return <Left />;
    case "menu":
      return <Menu />;
    case "message":
      return <Message />;
    case "notification":
      return <Notification />;
    case "notification-active":
      return <NotificationActive />;
    case "ok":
      return <Ok />;
    case "person":
      return <Person />;
    case "profile":
      return <Profile />;
    case "question":
      return <Question />;
    case "refresh":
      return <Refresh />;
    case "right":
      return <Right />;
    case "save":
      return <Save />;
    case "search":
      return <Search />;
    case "send":
      return <Send />;
    case "settings":
      return <Settings />;
    case "show":
      return <Show />;
    case "success":
      return <Success />;
    case "trash":
      return <Trash />;
    case "warning":
      return <Warning />;
    case "warning-sign":
      return <WarningSign />;

    default:
      throw "Provided icon name does not exist!";
  }
};

export default IconsSolid;
