import LegalEntitiesTable from "appassociates/components/LegalEntitiesTable";
import NaturalPersonsTable from "appassociates/components/NaturalPersonsTable";
import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";
import { useState } from "react";

const Associates = () => {
  const [isLegalEntity, setIsLegalEntity] = useState(true);

  return (
    <>
      <Heading>Lica (pravna/fizička)</Heading>

      <Section>
        <ButtonGroup itemsPosition="c">
          <Button size="md" onClick={() => setIsLegalEntity(true)} classes={isLegalEntity && "active"}>
            Pravna lica
          </Button>
          <Button size="md" onClick={() => setIsLegalEntity(false)} classes={!isLegalEntity && "active"}>
            Fizička lica
          </Button>
        </ButtonGroup>

        {isLegalEntity ? <LegalEntitiesTable /> : <NaturalPersonsTable />}
      </Section>
    </>
  );
};

export default Associates;
