import React from "react";
import ReactDOM from "react-dom";
// import "./assets/css/min/index.min.css";
import "./assets/css/index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { UserInfoProvider } from "applogin/contexts/UserInfoProvider";
import { PopupNotificationsProvider } from "common/contexts/PopupNotificationsProvider";
import { PopoverWindowProvider } from "common/contexts/PopoverWindowProvider";
import FormDataProvider from "common/contexts/FormDataProvider";
import GlobalDataProvider from "common/contexts/GlobalDataProvider";

ReactDOM.render(
  <React.StrictMode>
    <GlobalDataProvider>
      <UserInfoProvider>
        <PopupNotificationsProvider>
          <PopoverWindowProvider>
            <FormDataProvider>
              <App />
            </FormDataProvider>
          </PopoverWindowProvider>
        </PopupNotificationsProvider>
      </UserInfoProvider>
    </GlobalDataProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(); // AMEL: use unregister() to disable PWA

