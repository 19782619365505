import { IFormProps } from "common/interfaces/form";

const Form = (props: IFormProps) => {
  return (
    <form name={props.name} onSubmit={props.onSubmit} className={props.classes} id={props.id}>
      {props.children}
    </form>
  );
};

export default Form;
