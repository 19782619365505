import {
	getLegalEntitiesDropdown,
	getNaturalPersonsDropdown,
} from "appassociates/api/associates";
import {
	createDocWithLegalEntity,
	createDocWithLegalEntityId,
	createDocWithNaturalPerson,
	createDocWithNaturalPersonId,
	getFieldsCreateDocPartDocInfo,
	getFieldsCreateDocPartExistingDocItems,
	getFieldsCreateDocPartUnregisteredDocItems,
	getFieldsCreateDocPartExistingLegalEntity,
	getFieldsCreateDocPartUnregisteredLegalEntity,
	getFieldsCreateDocPartExistingNaturalPerson,
	getFieldsCreateDocPartDocUnregisteredNaturalPerson,
} from "appdocsout/api/documents";
import FormMultipartAuto from "common/components/forms/FormMultipartAuto";
import { getItemById, getItemsDropdown } from "appitems/api/items";
import { getCurrenciesDropdown } from "appunits/api/currencies";
import { getTaxesDropdown } from "appunits/api/taxes";
import { getUnitsOfMeasurementDropdown } from "appunits/api/units_of_measurement";
import {getCountriesDropdown} from "appunits/api/countries";
import Button from "common/components/btns/Button";
import ButtonGroup from "common/components/btns/ButtonGroup";
import Section from "common/components/layout_zones/Section";
import Heading from "common/components/text/Heading";
import { FormDataContext } from "common/contexts/FormDataContext";
import { useContext, useEffect, useState } from "react";

const getPreparedDataForSubmit = (docTypeId: string, data: any, useExistingRespective: boolean) => {
	let dataToSubmit: any = {
		doc_info: {},
		doc_items_existing: [],
		doc_items_unregistered: [],
	};
	if (data.doc_info) {
		dataToSubmit.doc_info = data.doc_info;
		dataToSubmit.doc_info.codename = docTypeId;
	}
	if (data.doc_items_existing) {
		dataToSubmit.doc_items_existing = Object.values(data.doc_items_existing);
	}
	if (data.doc_items_unregistered) {
		dataToSubmit.doc_items_unregistered = Object.values(data.doc_items_unregistered);
	}
	if (useExistingRespective && data.respective_id) {
		dataToSubmit.respective_id = data.respective_id.respective_id;
	} else if (data.doc_respective) {
		dataToSubmit.doc_respective = data.doc_respective;
	}
	return dataToSubmit;
};

const DocOutCreate = ({ docTypeId, title }: any) => {
	const blockIdLegalEntity = "doc_out_create_leg_ent";
	const blockIdNaturalPerson = "doc_out_create_nat_pers";
	//
	const blockIdDocInfo = "doc_info";
	const blockIdRespectiveExisting = "respective_id";
	const blockIdRespectiveUnregistered = "doc_respective";
	const blockIdDocItemsExisting = "doc_items_existing";
	const blockIdDocItemsUnregistered = "doc_items_unregistered";
	//
	const [data, setData] = useContext(FormDataContext);
	const [isLegalEntity, setIsLegalEntity] = useState(true);
	const [isRegistered, setIsRegistered] = useState(true);

	// setup dropdowns
	const commonDropdowns = {
		currency_id: getCurrenciesDropdown,
		vat_id: getTaxesDropdown,
		unit_of_measurement_id: getUnitsOfMeasurementDropdown,
		existing_item_id: getItemsDropdown,
		country_code_iso: getCountriesDropdown,
	};

	const funcsGetDropdownsLegalEnt = {
		...commonDropdowns,
		[blockIdRespectiveExisting]: getLegalEntitiesDropdown,
		// legal_entity_type_id: getLegalEntityTypesDropdown,
	};

	const funcsGetDropdownsNaturalPer = {
		...commonDropdowns,
		[blockIdRespectiveExisting]: getNaturalPersonsDropdown,
	};

	const funcsGetRowItem = {
		// you can add as many fields/functions as you want.
		existing_item_id: getItemById,
	};

	// setup form sections
	const commonFormSections = [
		{
			subheading: "Podaci o dokumentu:",
			sectionType: "form",
			blockId: blockIdDocInfo,
			funcGetFieldsAttrs: getFieldsCreateDocPartDocInfo,
		},
		{
			subheading: "Registrovane stavke:",
			sectionType: "table",
			blockId: blockIdDocItemsExisting,
			funcGetFieldsAttrs: getFieldsCreateDocPartExistingDocItems,
			funcsGetRowItem: funcsGetRowItem,
		},
		{
			subheading: "Neregistrovane stavke:",
			sectionType: "table",
			blockId: blockIdDocItemsUnregistered,
			funcGetFieldsAttrs: getFieldsCreateDocPartUnregisteredDocItems,
		},
	];

	const sectionRespectiveRegistered = {
		subheading: isLegalEntity ? "Lice (pravno):" : "Lice (fizičko)",
		sectionType: "form",
		blockId: blockIdRespectiveExisting,
		funcGetFieldsAttrs: isLegalEntity
			? getFieldsCreateDocPartExistingLegalEntity
			: getFieldsCreateDocPartExistingNaturalPerson,
	};

	const sectionRespectiveUnregistered = {
		subheading: isLegalEntity ? "Lice (pravno):" : "Lice (fizičko)",
		sectionType: "form",
		blockId: blockIdRespectiveUnregistered,
		funcGetFieldsAttrs: isLegalEntity
			? getFieldsCreateDocPartUnregisteredLegalEntity
			: getFieldsCreateDocPartDocUnregisteredNaturalPerson,
	};

	let formSectionsRespectiveRegistered = [...commonFormSections];
	formSectionsRespectiveRegistered.splice(0, 0, sectionRespectiveRegistered); // put form respective as first

	let formSectionsRespectiveUnregistered = [...commonFormSections];
	formSectionsRespectiveUnregistered.splice(0, 0, sectionRespectiveUnregistered); // put form respective as first

	// setup submit funcion
	let funcSubmit = null;
	if (isLegalEntity) {
		if (isRegistered) {
			funcSubmit = () =>
				createDocWithLegalEntityId(getPreparedDataForSubmit(docTypeId, data[blockIdLegalEntity], true));
		} else {
			funcSubmit = () => createDocWithLegalEntity(getPreparedDataForSubmit(docTypeId, data[blockIdLegalEntity], false));
		}
	} else {
		if (isRegistered) {
			funcSubmit = () =>
				createDocWithNaturalPersonId(getPreparedDataForSubmit(docTypeId, data[blockIdNaturalPerson], true));
		} else {
			funcSubmit = () =>
				createDocWithNaturalPerson(getPreparedDataForSubmit(docTypeId, data[blockIdNaturalPerson], false));
		}
	}

	useEffect(() => {
		setData({});
	}, [isRegistered]);

	return (
		<>
			<Heading>{title}</Heading>

			<Section>
				<ButtonGroup itemsPosition="c">
					<Button
						size="md"
						type="button"
						classes={isLegalEntity && "active"}
						onClick={() => setIsLegalEntity((c: any) => true)}
					>
						Pravno lice
					</Button>
					<Button
						size="md"
						type="button"
						classes={!isLegalEntity && "active"}
						onClick={() => setIsLegalEntity((c: any) => false)}
					>
						Fizičko lice
					</Button>
				</ButtonGroup>

				<ButtonGroup itemsPosition="c">
					<Button
						size="md"
						type="button"
						classes={isRegistered && "active"}
						onClick={() => setIsRegistered((c: any) => true)}
					>
						Spremljeno lice
					</Button>
					<Button
						size="md"
						type="button"
						classes={!isRegistered && "active"}
						onClick={() => setIsRegistered((c: any) => false)}
					>
						Nespremljeno lice
					</Button>
				</ButtonGroup>

				{isLegalEntity ? (
					<FormMultipartAuto
						blockId={blockIdLegalEntity}
						formSections={isRegistered ? formSectionsRespectiveRegistered : formSectionsRespectiveUnregistered}
						funcsGetDropdowns={funcsGetDropdownsLegalEnt}
						funcSubmit={funcSubmit}
					/>
				) : (
					<FormMultipartAuto
						blockId={blockIdNaturalPerson}
						formSections={isRegistered ? formSectionsRespectiveRegistered : formSectionsRespectiveUnregistered}
						funcsGetDropdowns={funcsGetDropdownsNaturalPer}
						funcSubmit={funcSubmit}
					/>
				)}
			</Section>
		</>
	);
};

export default DocOutCreate;
