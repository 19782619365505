import { GlobalDataContext } from "common/contexts/GlobalDataContext";
import { useState } from "react";

const GlobalDataProvider = ({ children }: any) => {
  const [data, setData] = useState({
    isMenuLeft: false,
  });

  /**
   * HOW TO USE IT
   *
   * just add data into the global object
   *
   * IN FUTURE we will make an enum or somehting for define exactly the names and
   * nesting levels of all data
   *
   */

  return (
    <>
      <GlobalDataContext.Provider value={[data, setData]}>{children}</GlobalDataContext.Provider>
    </>
  );
};

export default GlobalDataProvider;
