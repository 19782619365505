export const USER_CONSTS = {
  initialUserInfo: {
    id: 0,
    username: "",
    email: "",
    name: "",
    surname: "",
    isAdmin: false,
    token: "",
    tut: "",
    isAuthenticated: false,
    companyURL: "",
  },
};
