import Button from "common/components/btns/Button";
import IconTextBlock from "common/components/text/IconTextBlock";
import { PopupNotificationsContext } from "common/contexts/PopupNotificationsProvider";
import { useContext } from "react";
import { formatDate } from "common/utils/formatDate";

const PopupNotificationSmall = (props: any) => {
  const [popNotifState, setPopNotif] = useContext(PopupNotificationsContext);

  const handleCloseNotification= () => {
    let newArr = [...popNotifState];
    newArr.splice(props.listIndex, 1);
    setPopNotif([...newArr]);
  };

  return (
    <div className={`popup-notification-small ${props.notifType}`}>
      <Button size="md" layout="icon-only" icon="close" classes="close" onClick={handleCloseNotification}></Button>
      <IconTextBlock
        size="md"
        title={props.msg}
        descr={formatDate(props.dateTime, "D2+F/+M2+F/+Y2+F +h2+F:+m2")}
        iconLeft={props.notifType ? props.notifType : "notification"}
        classes="popup-content"
        iconBg="transparent"
      />
    </div>
  );
};

export default PopupNotificationSmall;
