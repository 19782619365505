import Heading from "common/components/text/Heading";
import CompaniesTable from "appadmin/components/CompaniesTable";
import CompanyAddForm from "appadmin/components/CompanyAddForm";
import Section from "common/components/layout_zones/Section";

const AdminCompanies = () => {
  return (
    <>
      <Heading>Naše firme</Heading>

      <Section>
        <CompanyAddForm />
      </Section>

      <Section>
        <CompaniesTable />
      </Section>
    </>
  );
};

export default AdminCompanies;
