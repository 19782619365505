import Heading from "common/components/text/Heading";

const UserSettings = () => {
  return (
    <>
      <Heading>Moje postavke</Heading>
    </>
  );
};

export default UserSettings;