import Heading from "common/components/text/Heading";

const AdminHome = () => {
  return (
    <>
      <Heading>Admin početna</Heading>
    </>
  );
};

export default AdminHome;
