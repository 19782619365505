import ENDPOINTS from "applogin/api/endpoints";
import axios from "axios";

export const loginGetToken = async (company_username: string, username: string, password: string) => {
	// const formData = new FormData();
	// formData.append("username", username);
	// formData.append("password", password);

	return await axios.post(ENDPOINTS.login, {
		company_username: company_username,
		username: username,
		password: password,
	});
};
