// import { getLegalEntityTypesDropdown } from "appassociates/api/associates";
import { createCompany, getFieldsCreateCompany } from "appcompany/api_admin/companies";
import FormAuto from "common/components/forms/FormAuto";
import Subheading from "common/components/text/Subheading";
import {getCountriesDropdown} from "appunits/api/countries";

const CompanyAddForm = () => {
  const blockId = "company_add";

  const funcsGetDropdowns = {
		country_code_iso: getCountriesDropdown,
		// legal_entity_type_id: getLegalEntityTypesDropdown,
	};

  return (
    <>
      <Subheading>Dodaj firmu:</Subheading>
      <FormAuto
        blockId={blockId}
        funcGetFieldsAttrs={getFieldsCreateCompany}
        funcSubmit={createCompany}
        funcsGetDropdowns={funcsGetDropdowns}
      ></FormAuto>
    </>
  );
};

export default CompanyAddForm;
