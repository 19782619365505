import Icons from "common/components/icons/Icons";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import { IInputFieldProps } from "common/interfaces/input_field";
import createNestedObject from "common/utils/createNestedObject";
import deleteFromNestedObject from "common/utils/delteFromNestedObject";
import { useContext, useEffect, useRef, useState } from "react";

const getInputFieldProps = (props: IInputFieldProps) => {
	const {
		type,
		id,
		name,
		value,
		includeDefaultValInData,
		placeholder,
		form,
		autoComplete,
		disabled,
		required,
		title,
		step,
		min,
		max,
	}: IInputFieldProps = props;
	let o: any = {}; // props object

	type && (o.type = type);
	id && (o.id = id);
	name && (o.name = name);
	// value && (o.value = value);
	includeDefaultValInData && (o.includeDefaultValInData = includeDefaultValInData);
	placeholder && (o.placeholder = placeholder);
	form && (o.form = form);
	autoComplete && (o.autoComplete = autoComplete);
	disabled && (o.disabled = disabled);
	required && (o.required = required);
	title && (o.title = title);
	step && (o.step = step);
	min && (o.min = min);
	max && (o.max = max);
	return o;
};

const getLayout = (props: any) => {
	switch (props.layout) {
		case "icon-only":
			return "input-type-icon";
		case "icon-left":
			return "input-type-icon-left";
		case "icon-right":
			return "input-type-icon-right";
		case "text-left":
			return "input-type-text-left";
		case "text-right":
			return "input-type-text-right";
		default: // empty is normal input field
			return "";
	}
};

const InputField = (props: IInputFieldProps) => {
	const [data, setData] = useContext(FormDataContext);
	const dataLevel: any = useContext(FormDataLevelContext);
	const [val, setVal] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);
	const clearDisabled = props.disableClearBtn || props.disabled ? "clear-disabled" : "";

	const setDefaultState = () => {
		setVal(props.defaultState);
		if (props.includeDefaultValInData !== false) {
			setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], props.defaultState) }));
		}
	};

	useEffect(() => {
		props.defaultState && setDefaultState();
	}, [props.defaultState]); // I changed [] to [props.defaultState] on 19.12.2022. Change if makes problems elsewhere.

	return (
		<div
			className={`input-btn-container ${getLayout(props)} size-${props.size} no-arrows-${props.noArrows} ${
				props.classes
			}`}
		>
			<div className="input-container">
				<div className="input-grid-container">
					{props.icon && (
						<div className="icon-container">
							<Icons icon={props.icon} />
						</div>
					)}
					{props.helperText && (
						<div className="helper-text-container">
							<span className="helper-text">{props.helperText}</span>
						</div>
					)}
					{!props.isTextarea ? (
						<input
							size={1}
							ref={inputRef}
							value={val}
							onChange={(e: any) => {
								const inpVal = e.target.value;
								setVal(inpVal);
								// Old way, nice, but somethimes you want to send an empty field (ex. update data: delete existing text)
								// if (inpVal === "") {
								// 	setData((c: any) => ({ ...deleteFromNestedObject(c, [...dataLevel, props.name]) }));
								// } else {
								// 	setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], inpVal) }));
								// }
								setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], inpVal) }));
							}}
							{...getInputFieldProps(props)}
						/>
					) : (
						<textarea
							size={1}
							ref={inputRef}
							value={val}
							onChange={(e: any) => {
								const inpVal = e.target.value;
								setVal(inpVal);
								// Old way, nice, but somethimes you want to send an empty field (ex. update data: delete existing text)
								// if (inpVal === "") {
								// 	setData((c: any) => ({ ...deleteFromNestedObject(c, [...dataLevel, props.name]) }));
								// } else {
								// 	setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], inpVal) }));
								// }
								setData((c: any) => ({ ...createNestedObject(c, [...dataLevel, props.name], inpVal) }));
							}}
							className={!val && "empty"}
							{...getInputFieldProps(props)}
						></textarea>
					)}
					<button
						tabIndex={-1}
						type="button"
						className={`btn-clear align-v-center ${clearDisabled}`}
						onClick={(e: any) => {
							setVal("");
							setData((c: any) => ({ ...deleteFromNestedObject(c, [...dataLevel, props.name]) }));
							inputRef.current!.focus();
						}}
					>
						<Icons icon="clear" />
					</button>
				</div>
			</div>
			{props.children}
		</div>
	);
};

export default InputField;
