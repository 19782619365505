import Button from "common/components/btns/Button";
import Icons from "common/components/icons/Icons";
import Section from "common/components/layout_zones/Section";
import SubSection from "common/components/layout_zones/SubSection";
import Heading from "common/components/text/Heading";
import Subheading from "common/components/text/Subheading";
import { useState } from "react";

const UserSupport = () => {
  const [notificationMsg, setNotificationMsg] = useState("");
  const supportEmail = "podrska@kadalis.com";

  return (
    <>
      <Heading>Moja podrška</Heading>
      <Subheading>Trebate podršku? Pišite na:</Subheading>
      <Section>
        <SubSection>
          <p>
            <span className="m-r-100">{supportEmail} </span>
            <Button
              size="sm"
              layout="icon-left"
              icon={notificationMsg ? "success" : "copy"}
              onClick={() => {
                navigator.clipboard.writeText(supportEmail);
                setTimeout(() => {
                  setNotificationMsg("Kopirano! Sada je možete zalijepiti u email koji napišete.");
                }, 500);
              }}
            >
              kopiraj adresu
            </Button>
          </p>
        </SubSection>
        <p style={{ height: "1rem" }}>
          {notificationMsg && <Icons icon="success" />} {notificationMsg}
        </p>
      </Section>
    </>
  );
};

export default UserSupport;
