import Checkbox from "common/components/inputs/Checkbox";
import FormDataLevelProvider from "common/contexts/FormDataLevelProvider";
import { IRowBtnsProps, ITableProps } from "common/interfaces/table";
import { formatDate } from "common/utils/formatDate";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useTable, useSortBy, useRowSelect, usePagination } from "react-table";
import Icons from "../../icons/Icons";
import Dropdown from "../../inputs/Dropdown";
import InputField from "../../inputs/InputField";
import Pagination from "./Pagination";
import Resizer from "../base_common/Resizer";
import Button from "common/components/btns/Button";
import { FormDataContext } from "common/contexts/FormDataContext";
import { FormDataLevelContext } from "common/contexts/FormDataLevelContext";
import createNestedObject from "common/utils/createNestedObject";
import { tableUtils } from "common/components/tables/utils";

const isOverflownH = (obj: any) => {
	if (obj) {
		const { clientWidth, clientHeight, scrollWidth, scrollHeight } = obj;
		return scrollHeight > clientHeight;
	}
	return false;
};

const showMoreBtnWhereOverflow = (isReadMode: boolean) => {
	if (!isReadMode) {
		return;
	}
	const el_arr: any = document.getElementsByClassName("content-wrapper");
	if (el_arr) {
		for (const el of el_arr) {
			if (isOverflownH(el)) {
				el.classList.remove("hide-more-btn");
			}
		}
	}
};

const showHideMore = (el: any) => {
	const target = el.currentTarget;
	const parent = target.parentElement;
	parent.classList.toggle("expand");
	if (parent.classList.contains("expand")) {
		target.innerHTML = "manje";
	} else {
		target.innerHTML = "više";
	}
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</>
	);
});

const getInputFieldProps = (key: string, attrs: any, defaultState: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object
	o.name = key;
	o.size = "md";
	o.disableClearBtn = true;
	o.autoComplete = false;
	o.includeDefaultValInData = false;
	defaultState && (o.defaultState = defaultState);
	// defaultVal && (o.value = defaultVal);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const getCheckboxProps = (key: string, attrs: any, defaultState: any) => {
	const { disabled, required, readOnly } = attrs;
	let o: any = {}; // props object

	o.name = key;
	o.size = "md";
	o.includeDefaultValInData = false;
	defaultState && (o.defaultState = defaultState);
	required && (o.required = true);
	(disabled || readOnly) && (o.disabled = true);
	readOnly && (o.title = "Ovo polje je neizmjenjivo");
	return o;
};

const tableEditField = (key: string, attrs: any, defaultState: any, dropdowns?: any) => {
	let fieldProps: any = {};
	if (attrs.type === "boolean") {
		fieldProps = getCheckboxProps(key, attrs, defaultState);
	} else {
		fieldProps = getInputFieldProps(key, attrs, defaultState);
	}

	if (attrs.dropdown && dropdowns) {
		return (
			// we need one more div to center vertically in table row
			<div style={{ display: "flex" }}>
				<Dropdown {...fieldProps} items={dropdowns[key]} />
			</div>
		);
	} else if (attrs.type === "boolean") {
		return <Checkbox {...fieldProps} />;
		// return <input {...fieldProps} type="checkbox" />;
	} else if (attrs.format == "date" || attrs.format == "date-time") {
		return <InputField {...fieldProps} type="date" min={formatDate(Date.now(), "Y4+F-+M2+F-+D2")} />;
	} else if (attrs.type === "number" || attrs.type === "integer") {
		return <InputField {...fieldProps} type="number" noArrows={true} />;
	} else if (attrs.type === "text") {
		return <InputField {...fieldProps} isTextarea={true} />;
	} else {
		return <InputField {...fieldProps} />;
	}
};

const tableReadField = (key: string, attrs: any, defaultState: any, dropdowns?: any) => {
	let fieldProps: any = {};
	if (attrs.type === "boolean") {
		fieldProps = getCheckboxProps(key, attrs, defaultState);
	} else {
		fieldProps = getInputFieldProps(key, attrs, defaultState);
	}

	if (attrs.dropdown && dropdowns) {
		return <Dropdown {...fieldProps} items={dropdowns[key]} disabled={true} />;
	} else if (attrs.type === "boolean") {
		return <Checkbox {...fieldProps} disabled={true} />;
		// return <input {...fieldProps} type="checkbox" disabled={true} />;
	} else if (attrs.format == "date" || attrs.format == "date-time") {
		if (defaultState) {
			return formatDate(defaultState, "D2+F.+M2+F.+Y4");
		} else {
			return "";
		}
	} else {
		return defaultState;
	}
};

const EditableCell: any = ({
	value: initialValue,
	fieldsAttrs,
	fieldsValues,
	dropdowns,
	column: { id },
	editActive,
}: any) => {
	const attrs = fieldsAttrs[id];

	if (!editActive) {
		return <>{tableReadField(id, attrs, initialValue, dropdowns)}</>;
	} else {
		let inVal = initialValue;
		if (attrs.format == "date" || attrs.format == "date-time") {
			inVal = formatDate(initialValue, "D2+F.+M2+F.+Y4");
		}
		return (
			<>
				{/* <div className="width-holder">{inVal}</div> */}
				{tableEditField(id, attrs, initialValue, dropdowns)}
			</>
		);
	}
};

const defaultColumn = {
	Cell: EditableCell,
};

const addColumForSelect = (hooks: any) => {
	hooks.visibleColumns.push((cols: any) => [
		{
			id: "selection",
			Header: ({ getToggleAllRowsSelectedProps }: any) => (
				<span>
					<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
				</span>
			),
			Cell: ({ row }: any) => (
				<span>
					<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
				</span>
			),
		},
		...cols,
	]);
};

/**
 * Use "row" or "page". Page is for pagination option, row is for everything else
 */

const Table = ({
	hasOptions,
	blockId,
	editActive,
	columns,
	data,
	fieldsAttrs,
	dropdowns,
	rowBtnsProps,
	isRead,
	isUpdate,
	classes,
	skipPageReset,
	setterColumnSort,
	currSortCol,
	sortDesc,
	setterPageNr,
	setterPageSize,
	customPageNr, // needed for remote pages fetching
}: ITableProps) => {
	const [dataContx, setDataContx] = useContext(FormDataContext);
	const dataLevel: any = useContext(FormDataLevelContext);
	// const [sortCol, setSortCol]: any = useState("");
	// const [sortDesc, setSortDesc]: any = useState(true);
	const [rowIdDatabaseId, setRowIdDatabaseId]: any = useState({}); // an object - key = rowId, value = DbID
	const tableConfig: any = {
		columns,
		data,
		fieldsAttrs,
		dropdowns,
		editActive,
		defaultColumn,
		// @ts-ignore
		initialState: { pageSize: tableUtils.getPageSize() },
		manualPagination: true, // needed for remote pages fetching
		pageCount: customPageNr, // needed for remote pages fetching
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, selectedRowIds },
	}: any = useTable({ ...tableConfig }, useSortBy, usePagination, useRowSelect, addColumForSelect);

	const setterPreviousPage = () => {
		// previousPage(); // old way (for offline data)
		// @ts-ignore
		setterPageNr(customPageNr - 1);
	};

	const setterNextPage = () => {
		// nextPage(); // old way (for offline data)
		// @ts-ignore
		setterPageNr(customPageNr + 1);
	};

	const setterGotoPage = (nr: number) => {
		// gotoPage(nr); // old way (for offline data)
		// @ts-ignore
		setterPageNr(nr);
	};

	const setterSetPageSize = (nr: number) => {
		tableUtils.savePageSize(nr);
		setPageSize(nr);
	};

	let tableType = "read";
	if (isRead) {
		tableType = "read";
	} else if (isUpdate) {
		tableType = "update";
	}

	const assignDatabaseIDsToRowIDs = () => {
		page.map((row: any, i: any) => {
			const idCellsArr = row.cells.filter((cell: any) => cell.column.id === "id");
			if (idCellsArr.length > 0) {
				// Database ID is: idCellsArr[0].value
				setRowIdDatabaseId((c: any) => ({ ...c, [row.id]: idCellsArr[0].value }));
			}
		});
	};

	useEffect(() => {
		let selectedDatabaseIds: any = [];
		Object.keys(selectedRowIds).map((rowId: any) => {
			selectedDatabaseIds.push(rowIdDatabaseId[rowId]);
		});
		setDataContx((c: any) => ({ ...createNestedObject(c, [...dataLevel, blockId], selectedDatabaseIds) }));
	}, [selectedRowIds]);

	useEffect(() => {
		setterPageSize(pageSize);
	}, [pageSize]);

	useEffect(() => {
		assignDatabaseIDsToRowIDs();
		showMoreBtnWhereOverflow(isRead);
	}, []);

	return (
		<>
			<FormDataLevelProvider blockId={blockId}>
				<div className="table-wrapper">
					<table {...getTableProps()} className={`table ${tableType} ${classes}`}>
						<thead>
							{headerGroups.map((gr: any) => (
								<tr {...gr.getHeaderGroupProps()} className="header-row">
									{rowBtnsProps && (
										<th className={`header-cell options-cell`}>
											<Resizer width="sm" />
											<div className="header-container">opcije</div>
										</th>
									)}
									{gr.headers.map((col: any) => {
										const ignoreCell = col.id === "selection" && (isUpdate || !hasOptions);
										if (col.id !== "id" && !ignoreCell) {
											let sortCol: any;
											if (col.id !== "selection") {
												const sorColAttr = fieldsAttrs[col.id].sort_col;
												sortCol = sorColAttr ? sorColAttr : col.id;
											}
											return (
												<th
													{...col.getHeaderProps()}
													className={`header-cell ${col.id === "selection" && "selection-cell"} width-${
														col.id !== "selection" && fieldsAttrs[col.id].width
													}`}
												>
													<Resizer width={col.id !== "selection" && fieldsAttrs[col.id].width} />
													<div className="header-container">
														{col.render("Header")}
														{col.id === "sssselection" && (
															<span className="nr-of-selected-rows">{Object.values(selectedRowIds).length}</span>
														)}
														{col.id !== "selection" && (
															<span
																className={`sort ${sortCol !== currSortCol && "not-active"}`}
																onClick={() => {
																	setterColumnSort(sortCol, sortCol === currSortCol ? !sortDesc : true);
																}}
															>
																{sortDesc ? <Icons icon="collapse" /> : <Icons icon="expand" />}
															</span>
														)}
														{/* Below is the old way - for offline data sorting */}
														{/* {col.id !== "selection" && (
															<span className={`sort ${!col.isSorted && "not-active"}`} {...col.getSortByToggleProps()}>
																{col.isSortedDesc ? <Icons icon="collapse" /> : <Icons icon="expand" />}
															</span>
														)} */}
													</div>
												</th>
											);
										}
									})}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{Object.values(data).length > 0 ? (
								page.map((row: any, i: any) => {
									prepareRow(row);
									const databaseId = rowIdDatabaseId[row.id];
									return (
										<FormDataLevelProvider key={row.id} blockId={databaseId ? databaseId : row.id}>
											<tr {...row.getRowProps()}>
												{rowBtnsProps && (
													<td className={`options-cell`}>
														{rowBtnsProps.map(({ text, layout, icon, color, func }: IRowBtnsProps) => {
															return (
																<Button
																	key={text}
																	size="xs"
																	color={color}
																	layout={layout}
																	icon={icon}
																	onClick={() => func(databaseId)}
																>
																	{text}
																</Button>
															);
														})}
													</td>
												)}
												{row.cells.map((cell: any) => {
													const ignoreCell = cell.column.id === "selection" && (isUpdate || !hasOptions);
													return (
														cell.column.id !== "id" &&
														!ignoreCell && (
															<td
																{...cell.getCellProps()}
																className={`data-cell ${cell.column.id === "selection" && "selection-cell"}`}
															>
																{/* We need div to easier control content (ex. height, overflow etc.). td is has not those options */}
																{cell.column.id === "selection" ? (
																	<>{cell.render("Cell")}</>
																) : (
																	<div className="content-wrapper hide-more-btn">
																		{cell.render("Cell")}
																		{isRead && (
																			<Button size="xs" classes="more-btn" onClick={(el: any) => showHideMore(el)}>
																				više
																			</Button>
																		)}
																	</div>
																)}
															</td>
														)
													);
												})}
											</tr>
										</FormDataLevelProvider>
									);
								})
							) : (
								<tr className="no-entries-row">
									<td className="data-cell" colSpan={100}>
										Nema unosa.
									</td>
								</tr>
							)}
						</tbody>
						{/* <tfoot>
          {footerGroups.map((gr: any) => (
            <tr {...gr.getFooterGroupProps()} className="header-row">
              {gr.headers.map((col: any) => (
                <td {...col.getFooterProps()} className="header-cell">
                  {col.render("Header")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot> */}
					</table>

					{/* {pageOptions.length > pageSize && ( */}
					<Pagination
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						pageOptions={pageOptions}
						pageCount={pageCount}
						// pageIndex={pageIndex} // old way (for offline data)
						pageIndex={customPageNr}
						pageSize={pageSize}
						setterPreviousPage={setterPreviousPage}
						setterNextPage={setterNextPage}
						setterGotoPage={setterGotoPage}
						setterSetPageSize={setterSetPageSize}
					/>
					{/* )} */}
				</div>
			</FormDataLevelProvider>
		</>
	);
};

export default Table;
